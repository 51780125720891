import classNames from './SimpleExample.module.css';
import { FC, useCallback, useEffect, useState } from 'react';
import { MyScene } from './MyScene';
import { ApplicationModule, Bootloader } from '@own/engine';

export const SimpleExample: FC = () => {
  const [app, setApp] = useState<ApplicationModule>();
  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);

  useEffect(() => {
    let isInitialized = false;
    let isDestroyed = false;
    let app: ApplicationModule | undefined;

    const makeApp = async () => {
      app = await new Bootloader().makeApp();

      setApp(app);
      await app.runScene(MyScene);
      setSceneIsLoaded(true);

      isInitialized = true;
      if (isDestroyed) app.destroyContext();
    };

    makeApp().catch((e) => {
      console.error(e);
    });

    return () => {
      isDestroyed = true;
      if (isInitialized && app) app.destroyContext();
      setApp(undefined);
      setSceneIsLoaded(false);
    };
  }, []);

  const engineCanvasRef = useCallback((node: HTMLDivElement) => {
    if (!node || !app) return;

    node.appendChild(app.getRendererDomElement());
  }, [app]);

  return (
    sceneIsLoaded ? <div
      id="test"
      ref={engineCanvasRef}
      className={classNames.root}
    /> : <span>Loading...</span>
  );
};
