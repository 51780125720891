import '@vue/compiler-core';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  Link,
  createHashRouter,
  RouteObject,
} from 'react-router-dom';
import { SimpleExample } from './pages/SimpleExample/SimpleExample';
import { AnimationExample } from './pages/AnimationExample/AnimationExample';
import { VrmExample } from './pages/VrmExample/VrmExample';
import { ChakraProvider } from '@chakra-ui/react';
import { PhysicExample } from './pages/PhysicExample/PhysicExample';
import { NormalizedRigExample } from './pages/NormalizedRigExample/NormalizedRigExample';
import { InputsExample } from './pages/InputsExample/InputsExample';
import { CameramanExample } from './pages/CameramanExample/CameramanExample';
import { CharacterControllerExample } from './pages/CharacterControllerExample/CharacterControllerExample';
import { VCameraColliderExample } from './pages/VCameraColliderExample/VCameraColliderExample';

const routes: RouteObject[] = [
  {
    path: '/simple-example',
    element: <SimpleExample />,
  },
  {
    path: '/animation-example',
    element: <AnimationExample />,
  },
  {
    path: '/vrm-example',
    element: <VrmExample />,
  },
  {
    path: '/physic-example',
    element: <PhysicExample />,
  },
  {
    path: '/normalized-rig-example',
    element: <NormalizedRigExample />,
  },
  {
    path: '/inputs-example',
    element: <InputsExample />,
  },
  {
    path: '/cameraman-example',
    element: <CameramanExample />,
  },
  {
    path: '/character-controller-example',
    element: <CharacterControllerExample />,
  },
  {
    path: '/v-camera-collider-example',
    element: <VCameraColliderExample />,
  }
];

const router = createHashRouter([...routes, {
  path: '/',
  element: (
    routes.map((route) => <Link
      style={{ display: 'block' }}
      key={route.path}
      to={route.path as string}
    >{route.path}</Link>)
  ),
}]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ChakraProvider>
    <RouterProvider router={router} />
  </ChakraProvider>,
);
