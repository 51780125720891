import * as Three from 'three';
import { Entity, ImageAsset, RigidBodyComponent, RigidBodyType, StaticPlaneColliderComponent } from '@own/engine';
import { MyScene } from '../MyScene';
import * as assetsUrls from '../assetsUrls';

export const buildFloor = (scene: MyScene): Entity => {
  const geometry = new Three.PlaneGeometry(100, 100);
  const material = new Three.MeshStandardMaterial({
    color: new Three.Color().setStyle('#5f91c4'),
    side: Three.DoubleSide,
  });
  const plane = scene.entityManager.makeEntity(new Three.Mesh(geometry, material));
  plane.object.position.set(0, -0.00001, 0);

  geometry.rotateX(Math.PI / 2);

  material.map = scene.assetBundle.getAsset(assetsUrls.prototypeTileImage, ImageAsset).texture.clone();
  material.map.repeat.set(40, 40);
  material.map.wrapS = Three.RepeatWrapping;
  material.map.wrapT = Three.RepeatWrapping;

  plane.addComponent(RigidBodyComponent, { mass: 0, type: RigidBodyType.Static });
  plane.addComponent(StaticPlaneColliderComponent);

  return plane;
};
