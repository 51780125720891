import * as Three from 'three';
import {
  FbxAsset,
  EcsModule,
  CameramanBrainComponent,
  CameramanVCameraCollider,
  AnimatorParametersType,
  AnimatorComponent,
  AnimatorController,
} from '@own/engine';
import {
  CharacterCameraControllerScript,
  CharacterControllerScript,
  CharacterLogicStateControllerScript,
  CharacterActionsScript,
  IdleState,
} from '../../../packages/character-controller';
import { MyScene } from '../MyScene';
import * as assetsUrls from '../assetsUrls';

export type BuildMixamoCharacterParams = {
  scene: MyScene;
  characterEntityName: string;
  active: boolean;
  p3CameraColliderIsActive: boolean;
  debugP3VCameraCollider: boolean;
  debugP3VCamera: boolean;
};

export const buildMixamoCharacter = (options: BuildMixamoCharacterParams) => {
  const {
    scene,
    characterEntityName,
    active,
    debugP3VCameraCollider,
    debugP3VCamera,
    p3CameraColliderIsActive,
  } = options;

  const entity = scene.entityManager.makeEntity();
  const firstPersonVCamera = scene.entityManager.makeEntity();
  const thirdPersonVCamera = scene.entityManager.makeEntity();
  const vCameraBrainComponent = scene.ctx.getModule(EcsModule)
    .componentManager.getComponentSetByType(CameramanBrainComponent).values().next().value as CameramanBrainComponent;

  entity.object.name = characterEntityName;
  firstPersonVCamera.object.name = `${characterEntityName} P1VCamera`;
  thirdPersonVCamera.object.name = `${characterEntityName} P3VCamera`;

  scene.add(firstPersonVCamera.object);
  scene.add(thirdPersonVCamera.object);

  for (let i = 0; i < 1; i++) {
    const fbxEntity = scene.assetBundle.getAsset(assetsUrls.mixamoModel, FbxAsset).makeEntity();
    fbxEntity.object.scale.setScalar(0.01);
    fbxEntity.object.rotation.y = Math.PI;
    entity.object.add(fbxEntity.object);
  }

  entity.addComponent(CharacterCameraControllerScript);
  entity.addComponent(CharacterLogicStateControllerScript);
  const characterController = entity.addComponent(CharacterControllerScript, {
    firstPersonVCamera,
    thirdPersonVCamera,
    vCameraBrain: vCameraBrainComponent.entity,
    isActive: active,
  });

  characterController.getP3VCameraComponent().debug = debugP3VCamera;

  if (p3CameraColliderIsActive) {
    thirdPersonVCamera.addComponent(CameramanVCameraCollider, {
      debug: debugP3VCameraCollider,
      collisionDamping: 0.98,
      excludedEntityNames: [characterEntityName],
    });
  }

  entity.addComponent(CharacterActionsScript);
  entity.addComponent(AnimatorComponent, { controller: makeAnimationController(scene) });

  entity.getComponent(CharacterLogicStateControllerScript).forceCurrentState(IdleState);

  return entity;
};

const makeAnimationController = (scene: MyScene): AnimatorController => {
  const animatorController = new AnimatorController();

  const walkingAnimationClip = scene.assetBundle.getAsset(assetsUrls.mixamoWalkAnimation, FbxAsset)
    .getAnimations()[0] as Three.AnimationClip;
  const idleAnimationClip = scene.assetBundle.getAsset(assetsUrls.mixamoIdleAnimation, FbxAsset)
    .getAnimations()[0] as Three.AnimationClip;
  const runningAnimationClip = scene.assetBundle.getAsset(assetsUrls.mixamoRunAnimation, FbxAsset)
    .getAnimations()[0] as Three.AnimationClip;

  const walkingState = animatorController.rootStateMachine.addState('walking');
  walkingState.setMotion(walkingAnimationClip);
  const idleState = animatorController.rootStateMachine.addState('idle');
  idleState.setMotion(idleAnimationClip);
  const runningState = animatorController.rootStateMachine.addState('running');
  runningState.setMotion(runningAnimationClip);

  animatorController.rootStateMachine.entryState.addTransition(idleState, { duration: 0 });

  const walkingToIdle = walkingState.addTransition(idleState, { duration: 0.25 });
  const walkToRun = walkingState.addTransition(runningState, { duration: 0.25 });

  const idleToWalk = idleState.addTransition(walkingState, { duration: 0.25 });
  const idleToRun = idleState.addTransition(runningState, { duration: 0.25 });

  const runningToIdle = runningState.addTransition(idleState, { duration: 0.25 });
  const runningToWalk = runningState.addTransition(walkingState, { duration: 0.25 });


  walkingToIdle.useExitTime = false;
  walkToRun.useExitTime = false;

  idleToWalk.useExitTime = false;
  idleToRun.useExitTime = false;

  runningToIdle.useExitTime = false;
  runningToWalk.useExitTime = false;

  animatorController.rootStateMachine.parameters.declareParameter('isWalking', AnimatorParametersType.Boolean);
  animatorController.rootStateMachine.parameters.declareParameter('isRunning', AnimatorParametersType.Boolean);

  walkingToIdle.conditions.addCondition('isWalking').value = false;
  walkingToIdle.conditions.addCondition('isRunning').value = false;

  walkToRun.conditions.addCondition('isWalking').value = false;
  walkToRun.conditions.addCondition('isRunning').value = true;

  idleToWalk.conditions.addCondition('isWalking').value = true;
  idleToWalk.conditions.addCondition('isRunning').value = false;

  idleToRun.conditions.addCondition('isWalking').value = false;
  idleToRun.conditions.addCondition('isRunning').value = true;

  runningToIdle.conditions.addCondition('isWalking').value = false;
  runningToIdle.conditions.addCondition('isRunning').value = false;

  runningToWalk.conditions.addCondition('isWalking').value = true;
  runningToWalk.conditions.addCondition('isRunning').value = false;

  return animatorController;
};
