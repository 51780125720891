import { ScriptComponent, ScriptComponentOptions, ScriptComponentProps } from '@own/engine';
import { CharacterLogicState } from './states/CharacterLogicState';
import { CharacterControllerScript } from './CharacterController.script';

export type CharacterStateControllerScriptProps = ScriptComponentProps & {
  initialState?: new (cc: CharacterControllerScript) => CharacterLogicState;
};

export class CharacterLogicStateControllerScript extends ScriptComponent<CharacterStateControllerScriptProps> {
  protected _currentState?: CharacterLogicState;

  constructor(options: ScriptComponentOptions<CharacterStateControllerScriptProps>) {
    super(options);

    if (options.props?.initialState) this.forceCurrentState(options.props.initialState);
  }

  public forceCurrentState<T extends CharacterLogicState>(StateType: new (cc: CharacterControllerScript) => T): void {
    this._currentState?.exit(0);
    this._currentState = new StateType(this.entity.getComponent(CharacterControllerScript));
    this._currentState.enter(0);
  }

  public update(): void {
    if (!this.entity.getComponent(CharacterControllerScript).controllerIsActive) return;

    if (!this._currentState) return;

    this._currentState = this._currentState.update(this.time.timeDelta);
  }
}
