import * as Three from 'three';

export const buildLights = (): Three.Light[] => {
  const spotLight = new Three.SpotLight(new Three.Color().setStyle('#cdeefc'), 4);
  const ambientLight = new Three.AmbientLight(0x444444, 2);
  const hemiLight = new Three.HemisphereLight(0xffeeb1, 0x080820, 4);

  // vrm don't support shadows
  const isVrm = false;
  if (!isVrm) {
    spotLight.position.set(10, 5, 10);
    spotLight.castShadow = true;
    spotLight.shadow.bias = -0.002;
    spotLight.shadow.radius = 4;
    spotLight.shadow.mapSize.width = 1024 * 4;
    spotLight.shadow.mapSize.height = 1024 * 4;
  }

  return [
    spotLight,
    hemiLight,
    ambientLight,
  ];
};
