import * as Three from 'three';
import {
  Scene,
  VRM_AVATAR_TYPE,
  VrmAsset,
  FbxAsset,
  Entity,
  AnimatorComponent,
  AnimatorController,
  MIXAMO_AVATAR_TYPE,
  VrmComponent, PerspectiveCameraComponent,
} from '@own/engine';

const vrmGirl = '/mirafinal.vrm';
const jumpAnimation = '/JumpingDown.fbx';

export class MyScene extends Scene {
  public prepareAssets() {

    this.assetBundle.registerAsset({ path: vrmGirl }, VrmAsset);
    this.assetBundle.registerAsset({ path: jumpAnimation }, FbxAsset);
  }

  public buildScene() {
    const camera = this.entityManager.makeEntity(new Three.PerspectiveCamera());
    const light = new Three.DirectionalLight(0xFFFFFF);
    const helper = new Three.DirectionalLightHelper(light, 5);

    camera.object.position.z = -5;
    camera.object.position.y = 5;
    camera.object.position.x = 5;
    camera.object.lookAt(0, 0, 0)
    camera.addComponent(PerspectiveCameraComponent);

    const girl1 = this.makeCharacterEntity();

    this.add(camera.object);
    this.add(light);
    this.add(helper);
    this.add(girl1.object);

    let i = 0;

    setInterval(() => {
      if (i > 11) return;

      i++;
      const dynamicGirl = this.makeCharacterEntity();
      dynamicGirl.object.position.x = Math.random() * 10 - 5;
      dynamicGirl.object.position.z = Math.random() * 10 - 5;
      this.add(dynamicGirl.object);
    }, 500);
  }

  protected makeCharacterEntity(): Entity {
    const vrmGirlAsset = this.assetBundle.getAsset(vrmGirl, VrmAsset);
    const jumpAnimationAsset = this.assetBundle.getAsset(jumpAnimation, FbxAsset);

    const vrmGirlEntity = vrmGirlAsset.makeAvatarEntity();
    const animationController = new AnimatorController();
    const animatorComponent = vrmGirlEntity.addComponent(AnimatorComponent);

    const jumpClip = jumpAnimationAsset.animationClips[0];

    if (!jumpClip) throw new Error('No jump clip');

    animatorComponent.controller = animationController;
    animationController.rootStateMachine.entryState.setMotion(jumpClip);

    animatorComponent.clipRetargeter.updateRetargetEntry({
      sourceClip: jumpClip,
      sourceClipRig: jumpAnimationAsset.rig,
      sourceClipAvatarType: MIXAMO_AVATAR_TYPE,
    });
    animatorComponent.clipRetargeter.setTargetAvatarType(VRM_AVATAR_TYPE);

    vrmGirlEntity.getComponent(VrmComponent).vrm?.expressionManager?.setValue('aa', 1);
    vrmGirlEntity.getComponent(VrmComponent).vrm?.expressionManager?.setValue('blinkLeft', 1);

    return vrmGirlEntity;
  }
}
