import * as ReactFlow from 'reactflow';

/**
 * this helper function returns the intersection point of the line between the center of the intersectionNode and the target node
 *
 * @see https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
 *
 * @param intersectionNode
 * @param targetNode
 */
export const getNodeIntersection = (intersectionNode: ReactFlow.Node, targetNode: ReactFlow.Node): { x: number, y: number } => {
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const { positionAbsolute: targetPosition } = targetNode;

  if (!intersectionNodeWidth || !intersectionNodeHeight) throw new Error('intersectionNode has no width or height');
  if (!intersectionNodePosition) throw new Error('intersectionNode has no position');
  if (!targetPosition) throw new Error('intersectionNode has no position');

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * (w)) - (y1 - y2) / (2 * (h));
  const yy1 = (x1 - x2) / (2 * (w)) + (y1 - y2) / (2 * (h));
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
};
