import * as Three from 'three';
import {
  Scene,
  VrmAsset,
  FbxAsset,
  Entity,
  RigidBodyComponent,
  RigidBodyType,
  StaticPlaneColliderComponent,
  PerspectiveCameraComponent,
} from '@own/engine';
import { InputExampleScript } from './scripts/InputExample.script';

const vrmGirl = '/mirafinal.vrm';
const jumpAnimation = '/JumpingDown.fbx';

export class MyScene extends Scene {
  public prepareAssets() {
    this.assetBundle.registerAsset({ path: vrmGirl }, VrmAsset);
    this.assetBundle.registerAsset({ path: jumpAnimation }, FbxAsset);
  }

  public buildScene() {
    const camera = this.entityManager.makeEntity(new Three.PerspectiveCamera());
    const light = new Three.DirectionalLight(0xffffff, 3);
    light.position.set(1, 1, 1).normalize();

    camera.addComponent(PerspectiveCameraComponent);

    const inputExampleScript = this.entityManager.makeEntity();
    inputExampleScript.addComponent(InputExampleScript);

    this.add(camera.object);
    this.add(inputExampleScript.object);
    this.add(light);
    this.add(this.makePlane().object);
    this.add(new Three.AxesHelper(5));
  }

  protected makePlane(): Entity {
    const geometry = new Three.PlaneGeometry(10, 10);
    geometry.rotateX(Math.PI / 2);
    const material = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#5f91c4'), side: Three.DoubleSide });
    const plane = this.entityManager.makeEntity(new Three.Mesh());

    plane.object.name = 'floor';

    plane.getObjectAs(Three.Mesh).geometry = geometry;
    plane.getObjectAs(Three.Mesh).material = material;

    const rb = plane.addComponent(RigidBodyComponent);
    rb.type = RigidBodyType.Static;
    rb.mass = 0;
    plane.addComponent(StaticPlaneColliderComponent);


    return plane;
  }
}
