import * as ReactFlow from 'reactflow';
import { getNodeIntersection, getEdgePosition } from './index';

export type GetEdgeParamsResult = {
  sx: number,
  sy: number,
  tx: number,
  ty: number,
  sourcePos: ReactFlow.Position,
  targetPos: ReactFlow.Position,
}

/**
 * returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
 */
export function getEdgeParams(from: ReactFlow.Node, to: ReactFlow.Node): GetEdgeParamsResult  {
  const sourceIntersectionPoint = getNodeIntersection(from, to);
  const targetIntersectionPoint = getNodeIntersection(to, from);

  const sourcePos = getEdgePosition(from, sourceIntersectionPoint);
  const targetPos = getEdgePosition(to, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}
