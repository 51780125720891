import { ScriptComponent, ScriptComponentProps } from '@own/engine';
import { ComponentOptions, Entity } from '@own/engine';
import { AnimatorComponent } from '@own/engine';
import { CapsuleColliderComponent, RigidBodyComponent, RigidBodyType } from '@own/engine';
import * as Three from 'three';
import { CameramanBrainComponent, CameramanVCameraComponent } from '@own/engine';
import { SpaceMode } from '@own/engine';
import { POVLookAt } from '@own/engine';
import { LockToTargetFollow } from '@own/engine';
import { FramingTransposerFollow } from '@own/engine';

export type CharacterControllerScriptProps = ScriptComponentProps & {
  thirdPersonVCamera?: Entity;
  firstPersonVCamera?: Entity;
  vCameraBrain?: Entity;
  isActive?: boolean;
  baseWalkSpeedK?: number;
}

export class CharacterControllerScript extends ScriptComponent<CharacterControllerScriptProps> {
  public p3rotationSpeed: number = Math.PI * 2;

  public moveSpeed: number = 1;

  public p3VCamera?: Entity;

  public p1VCamera?: Entity;

  public vCameraBrain?: Entity;

  public moveDirection = new Three.Vector2();

  public cameraRotationDelta: Three.Vector2 = new Three.Vector2();

  public p3CameraMinDistance: number = 2;

  public p3CameraMaxDistance: number = 7;

  public p3CameraDistanceDelta: number = 0;

  public p3CameraDistanceSpeed: number = 1;

  public p3MinCameraVerticalAngle: number = -80 * (Math.PI / 180);

  public p3MaxCameraVerticalAngle: number = 80 * (Math.PI / 180);

  public p3MinCameraHorizontalAngle: number = -Infinity;

  public p3MaxCameraHorizontalAngle: number = Infinity;

  public p1MinCameraVerticalAngle: number = -80 * (Math.PI / 180);

  public p1MaxCameraVerticalAngle: number = 80 * (Math.PI / 180);

  public p1MinCameraHorizontalAngle: number = -Infinity;

  public p1MaxCameraHorizontalAngle: number = Infinity;

  public cameraMode: 'first-person' | 'third-person' = 'third-person';

  protected _controllerIsActive: boolean = true;

  public get controllerIsActive(): boolean {
    return this._controllerIsActive;
  }

  public set controllerIsActive(value: boolean) {
    this._controllerIsActive = value;

    if (!value) {
      const rigidBodyComponent = this.entity.getComponent(RigidBodyComponent);
      rigidBodyComponent.type = RigidBodyType.Static;
      rigidBodyComponent.mass = 0;
      rigidBodyComponent.needsUpdate = true;
    } else {
      const rigidBodyComponent = this.entity.getComponent(RigidBodyComponent);
      rigidBodyComponent.type = RigidBodyType.Dynamic;
      rigidBodyComponent.mass = 1;
      rigidBodyComponent.needsUpdate = true;
    }
  }

  public get cameraModeBlendingIsActive(): boolean {
    const { activeBlendState } = this.getVCameraBrainComponent();

    if (activeBlendState === undefined) return false;

    const ownCameras = [this.getP1VCameraComponent(), this.getP3CameraRotation()];

    return ownCameras.includes(activeBlendState.from) || ownCameras.includes(activeBlendState.to);
  }

  public get cameraModeBlendingNormalizedTime(): number {
    if (!this.cameraModeBlendingIsActive) return -1;

    const { activeBlendState } = this.getVCameraBrainComponent();

    if (!activeBlendState) return -1;

    return activeBlendState.time / activeBlendState.duration;
  }

  protected get animatorComponent(): AnimatorComponent {
    return this.entity.getComponent(AnimatorComponent);
  }

  protected get rigidBodyComponent(): RigidBodyComponent {
    return this.entity.getComponent(RigidBodyComponent);
  }

  public getP3VCameraComponent(): CameramanVCameraComponent {
    if (!this.p3VCamera) throw new Error('Third person vCamera is not set');

    return this.p3VCamera.getComponent(CameramanVCameraComponent);
  }

  public getP1VCameraComponent(): CameramanVCameraComponent {
    if (!this.p1VCamera) throw new Error('First person vCamera is not set');

    return this.p1VCamera.getComponent(CameramanVCameraComponent);
  }

  public getVCameraBrainComponent(): CameramanBrainComponent {
    if (!this.vCameraBrain) throw new Error('vCamera is not set');

    return this.vCameraBrain.getComponent(CameramanBrainComponent);
  }

  constructor(options: ComponentOptions<CharacterControllerScriptProps>) {
    super(options);
    this.p1VCamera = options.props?.firstPersonVCamera;
    this.p3VCamera = options.props?.thirdPersonVCamera;
    this.vCameraBrain = options.props?.vCameraBrain;

    this.buildPhysicsComponents();
    // todo: implement configure diffirent camera blending
    this.getVCameraBrainComponent().blendTime = 1;

    if (this.p3VCamera) this.buildP3VCameraComponent(this.p3VCamera);
    if (this.p1VCamera) this.buildP1VCameraComponent(this.p1VCamera);

    this.controllerIsActive = options.props?.isActive ?? this.controllerIsActive;
  }

  public fixedUpdate() {
    this.entity.getComponent(RigidBodyComponent).velocity.y = 0;
  }

  public update() {
    this.handleCameraModeChange();
    this.handleP3Mode();
    this.handleP1Mode();
  }

  public setP3CameraDistance(distance: number) {
    this.getP3VCameraComponent().getFollowAs(FramingTransposerFollow).distance = distance;
  }

  public setP3CameraRotation(rotation: Three.Vector2) {
    this.clampP3CameraRotation(rotation);
    this.getP3VCameraComponent().getLookAtAs(POVLookAt).horizontalAngle = rotation.x;
    this.getP3VCameraComponent().getLookAtAs(POVLookAt).verticalAngle = rotation.y;
  }

  public setP1CameraRotation(rotation: Three.Vector2) {
    this.clampP1CameraRotation(rotation);
    this.getP1VCameraComponent().getLookAtAs(POVLookAt).horizontalAngle = rotation.x;
    this.getP1VCameraComponent().getLookAtAs(POVLookAt).verticalAngle = rotation.y;
  }

  public getP3CameraRotation(): Three.Vector2 {
    return new Three.Vector2(
      this.getP3VCameraComponent().getLookAtAs(POVLookAt).horizontalAngle,
      this.getP3VCameraComponent().getLookAtAs(POVLookAt).verticalAngle,
    );
  }

  public getP1CameraRotation(): Three.Vector2 {
    return new Three.Vector2(
      this.getP1VCameraComponent().getLookAtAs(POVLookAt).horizontalAngle,
      this.getP1VCameraComponent().getLookAtAs(POVLookAt).verticalAngle,
    );
  }

  protected handleP3Mode() {
    if (this.cameraMode !== 'third-person') return;

    this.handleP3CameraRotationDelta();
    this.handleP3CameraDistanceDelta();
    this.handleP3MoveDirection();
  }

  protected handleP1Mode() {
    if (this.cameraMode !== 'first-person') return;

    this.handleP1CameraRotationDelta();
    this.handleP1MoveDirection();
  }

  protected handleP1MoveDirection() {
    if (this.moveDirection.length() === 0) {
      this.rigidBodyComponent.velocity.set(0, 0, 0);
      return;
    }

    const moveDirection = new Three.Vector3(this.moveDirection.x, 0, this.moveDirection.y).normalize();

    moveDirection.transformDirection(this.getP1VCameraComponent().entity.object.matrix).setY(0).normalize().multiplyScalar(
      this.moveSpeed,
    );

    this.rigidBodyComponent.velocity.copy(moveDirection);
  }

  protected handleP1CameraRotationDelta() {
    if (this.cameraRotationDelta.length() === 0) return;

    const povLookAt = this.getP1VCameraComponent().getLookAtAs(POVLookAt);

    const rotation = new Three.Vector2(
      povLookAt.horizontalAngle - this.cameraRotationDelta.x,
      povLookAt.verticalAngle + this.cameraRotationDelta.y,
    );
    this.clampP1CameraRotation(rotation);

    povLookAt.horizontalAngle = rotation.x;
    povLookAt.verticalAngle = rotation.y;

    const targetCharacterYRotation = new Three.Quaternion().setFromEuler(
      new Three.Euler(0, povLookAt.horizontalAngle, 0),
    );

    this.rigidBodyComponent.quaternion.rotateTowards(targetCharacterYRotation, 1);


    this.cameraRotationDelta.set(0, 0);
  }

  protected handleP3CameraRotationDelta() {
    if (!this.cameraRotationDelta.length()) return;

    const lookAt = this.getP3VCameraComponent().getLookAtAs(POVLookAt);

    const rotation = new Three.Vector2(
      lookAt.horizontalAngle - this.cameraRotationDelta.x,
      lookAt.verticalAngle + this.cameraRotationDelta.y,
    );

    this.clampP3CameraRotation(rotation);

    lookAt.horizontalAngle = rotation.x;
    lookAt.verticalAngle = rotation.y;

    this.cameraRotationDelta.set(0, 0);
  }

  protected handleP3CameraDistanceDelta(): void {
    if (this.p3CameraDistanceDelta === 0) return;

    const follow = this.getP3VCameraComponent().getFollowAs(FramingTransposerFollow);

    follow.distance += this.p3CameraDistanceDelta * this.p3CameraDistanceSpeed;
    follow.distance = Three.MathUtils.clamp(follow.distance, this.p3CameraMinDistance, this.p3CameraMaxDistance);

    this.p3CameraDistanceDelta = 0;
  }

  protected handleP3MoveDirection(): void {
    if (this.moveDirection.length() === 0) {
      this.rigidBodyComponent.velocity.set(0, 0, 0);
      return;
    }

    const moveDirection = new Three.Vector3(this.moveDirection.x, 0, this.moveDirection.y);

    const targetQuaternion = new Three.Quaternion().setFromRotationMatrix(new Three.Matrix4().lookAt(
      new Three.Vector3(),
      moveDirection.transformDirection(this.getP3VCameraComponent().entity.object.matrix).setY(0),
      new Three.Vector3(0, 1, 0),
    ));

    this.rigidBodyComponent.quaternion.rotateTowards(targetQuaternion, this.p3rotationSpeed * this.time.timeDelta);

    const moveFactor = this.moveDirection.normalize().length() * this.moveSpeed;

    const translateDelta = new Three.Vector3(0, 0, -moveFactor).applyQuaternion(this.rigidBodyComponent.quaternion);

    this.rigidBodyComponent.velocity.copy(translateDelta);

  }

  protected handleCameraModeChange() {
    if (this.getVCameraBrainComponent().activeBlendState !== undefined) {
      // todo: implement change blend direction
      return;
    }

    this.getP1VCameraComponent().isActive = this.controllerIsActive && this.cameraMode === 'first-person';
    this.getP3VCameraComponent().isActive = this.controllerIsActive && this.cameraMode === 'third-person';
  }

  protected clampP3CameraRotation(rotation: Three.Vector2): void {
    rotation.x = Three.MathUtils.clamp(rotation.x, this.p3MinCameraHorizontalAngle, this.p3MaxCameraHorizontalAngle);
    rotation.y = Three.MathUtils.clamp(rotation.y, this.p3MinCameraVerticalAngle, this.p3MaxCameraVerticalAngle);
  }

  protected clampP1CameraRotation(rotation: Three.Vector2): void {
    rotation.x = Three.MathUtils.clamp(rotation.x, this.p1MinCameraHorizontalAngle, this.p1MaxCameraHorizontalAngle);
    rotation.y = Three.MathUtils.clamp(rotation.y, this.p1MinCameraVerticalAngle, this.p1MaxCameraVerticalAngle);
  }

  protected buildPhysicsComponents(): void {
    this.entity.addComponent(RigidBodyComponent, {
      type: RigidBodyType.Dynamic,
      mass: 1,
      angularVelocityFactor: new Three.Vector3(0, 0, 0),
      friction: 0,
    });
    this.entity.addComponent(CapsuleColliderComponent, {
      radius: 0.55,
      height: 0.9,
      center: new Three.Vector3(0, 1, 0),
    });
  }

  protected buildP1VCameraComponent(p1VCamera: Entity): void {
    p1VCamera.addComponent(CameramanVCameraComponent, {
      isActive: false,
      followTarget: this.entity,
      pipelineOrder: 'lookAtThenFollow',
      follow: new LockToTargetFollow({
        damping: 0,
        followOffset: new Three.Vector3(0, 1.6, 0),
        spaceMode: SpaceMode.World,
      }),
      lookAt: new POVLookAt({
        damping: 0.4,
      }),
      fov: 40,
      near: 0.15,
      far: 200,
      fovDamping: 0.5,
    });
  }

  protected buildP3VCameraComponent(p3VCamera: Entity): void {
    p3VCamera.addComponent(CameramanVCameraComponent, {
      isActive: false,
      followTarget: this.entity,
      debug: this.entity.object.name === 'mixamoCharacter',
      pipelineOrder: 'lookAtThenFollow',
      follow: new FramingTransposerFollow({
        screenX: -0.5,
        screenY: -0.5,
        screenMode: 'distance-based',
        distanceBasedScreenXFactor: 1,
        distanceBasedScreenYFactor: 1,
        distance: 2,
        distanceDamping: 0.8,
        targetOffset: new Three.Vector3(0, 1.3, 0),
        targetOffsetDamping: 0.8,
        screenDamping: 0.8,
      }),
      lookAt: new POVLookAt({
        damping: 0.6,
      }),
      fov: 50,
      near: 0.1,
      far: 200,
      fovDamping: 0.9,
    });
  }
}
