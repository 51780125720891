import { Context, ApplicationModule, AnimatorComponent } from '@own/engine';
import { ReactNode, useCallback, useContext, useMemo } from 'react';
import { AssetModule } from '@own/engine';
import { EcsModule } from '@own/engine';
import { EngineContext } from '../context';

export type EngineProviderProps = {
  children: ReactNode
  context: Context,
}

export const useEngine = () => {
  const { context } = useContext(EngineContext);

  const getContextOrThrow = useCallback(() => {
    if (!context) throw new Error('Context not defined');

    return context;
  }, [context]);

  const assetManager = useMemo(() => {
    return getContextOrThrow().getModule(AssetModule).assetManager;
  }, [getContextOrThrow]);

  const componentManager = useMemo(() => {
    return getContextOrThrow().getModule(EcsModule).componentManager;
  }, [getContextOrThrow]);

  const applicationModule = useMemo(() => {
    return getContextOrThrow().getModule(ApplicationModule);
  }, [getContextOrThrow]);

  const getAnyAnimationController = useCallback(() => {
    const animatorComponents = getContextOrThrow().getModule(EcsModule)
      .componentManager
      .getComponentSetByType(AnimatorComponent)

    const controller = Array.from(animatorComponents).shift()?.controller;

    if (!controller) throw new Error('Controller not found');

    return controller;
  }, [getContextOrThrow]);

  return {
    context,
    assetManager,
    componentManager,
    applicationModule,
    getAnyAnimationController,
  };
};
