import { FC, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  HStack,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEditorStore } from '../../store';
import { useEngine } from '../../hooks';
import { AnimatorParametersType } from '@own/engine';
import { PanelInputNumberField } from '../PanelInputNumberField';
import { PanelCheckboxField } from '../PanelCheckboxField';

export const ParametersEditPanel: FC = () => {
  const { parameterEditPanel } = useEditorStore();
  const { getAnyAnimationController } = useEngine();
  // todo: very bad (but fast) ui solution, need sync all controller state in store
  const [, setRerender] = useState(0);
  const [newParameterName, setNewParameterName] = useState('');
  const [newParameterType, setNewParameterType] = useState<AnimatorParametersType>(AnimatorParametersType.Number);
  const toast = useToast();

  const incrementRerender = () => setRerender((prev) => prev + 1);

  const { parameters } = getAnyAnimationController().rootStateMachine;

  if (!parameterEditPanel.isActive) return null;
  if (!parameters) return null;

  return <Card
    width="100%"
    height="100%"
    key="parameters"
  >
    <CardHeader>
      <Text fontSize="xl">Parameters</Text>
    </CardHeader>
    <CardBody>
      <FormControl>
        {parameters.getParameterNames().map((name) => {
          const type = parameters.getParameterType(name);
          const value = parameters.getParameterValue(name);

          return <div key={name}>
            {type === AnimatorParametersType.Number && <PanelInputNumberField
              name={name}
              key={name}
              defaultValue={value as number}
              removable
              onChange={(value) => {
                parameters.setParameterValue(name, value);
                incrementRerender();
              }}
              onRemove={() => {
                parameters.deleteParameter(name);
                incrementRerender();
              }}
            />}
            {type === AnimatorParametersType.Boolean && <PanelCheckboxField
              name={name}
              key={name}
              checked={value as boolean}
              removable
              onChange={(value) => {
                parameters.setParameterValue(name, value);
                incrementRerender();
              }}
              onRemove={() => {
                parameters.deleteParameter(name);
                incrementRerender();
              }}
            />}
            {type === AnimatorParametersType.Trigger && <PanelCheckboxField
              name={name}
              key={name}
              checked={value as boolean}
              removable
              onChange={(value) => {
                parameters.setParameterValue(name, value);
                incrementRerender();
              }}
              onRemove={() => {
                parameters.deleteParameter(name);
                incrementRerender();
              }}
            />}
          </div>;
        })}
        <HStack>
          <Input
            value={newParameterName}
            size="xs"
            onChange={(event) => setNewParameterName(event.target.value)}
          />
          <Select
            required
            defaultValue={newParameterType}
            size="xs"
            onChange={(e) => setNewParameterType(e.target.value as AnimatorParametersType)}
          >
            <option value={AnimatorParametersType.Boolean}>Boolean</option>
            <option value={AnimatorParametersType.Number}>Number</option>
          </Select>
          <Button
            colorScheme="blue"
            size="xs"
            width="100px"
            isDisabled={!newParameterName || !newParameterType}
            onClick={() => {
              try {
                parameters.declareParameter(newParameterName, newParameterType);
                setNewParameterName('');
              } catch (e) {
                toast({
                  title: 'Parameter add error',
                  description: e instanceof Error ? e.message : 'Unknown error',
                  status: 'error',
                  duration: 4000,
                  position: 'top',
                  isClosable: true,
                });
              }
            }}
          >
            Add
          </Button>
        </HStack>
      </FormControl>
    </CardBody>
  </Card>;
};
