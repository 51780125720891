import * as ReactFlow from 'reactflow';

/**
 * Returns the position (top,right,bottom or right) passed node compared to the intersection point
 *
 * @param node
 * @param intersectionPoint
 */
export const getEdgePosition = (node: ReactFlow.Node, intersectionPoint: { x: number, y: number }): ReactFlow.Position => {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n?.x ?? 0);
  const ny = Math.round(n?.y ?? 0);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return ReactFlow.Position.Left;
  }
  if (px >= nx + (n?.width || 0) - 1) {
    return ReactFlow.Position.Right;
  }
  if (py <= ny + 1) {
    return ReactFlow.Position.Top;
  }
  if (py >= (n?.y ?? 0) + (n?.height ?? 0) - 1) {
    return ReactFlow.Position.Bottom;
  }

  return ReactFlow.Position.Top;
};
