import * as Three from 'three';
import GUI from 'lil-gui';
import Stats from 'three/examples/jsm/libs/stats.module';
import { TeapotGeometry } from 'three/examples/jsm/geometries/TeapotGeometry';
import {
  ScriptComponent,
  BoxColliderComponent,
  CapsuleColliderComponent,
  PhysicsModule,
  RigidBodyComponent,
  RigidBodyType,
  TriangleMeshColliderComponent,
  ConvexHullColliderComponent,
  TimeModule, Entity,
} from '@own/engine';

export class ObjectsManager extends ScriptComponent {
  public objectsCount = 0;

  public gravity = -9.81;

  public debuggerIsActive = true;

  public objectGeometry: 'box' | 'torus' | 'capsule' | 'teapot' = 'box';

  public objectRestitution = 0.1;

  public colliderShape: 'box' | 'convexHull' | 'capsule' | 'triangleMesh' = 'box';

  public fixedStepsCount = 60;

  protected _objects: Entity[] = [];

  protected _needsUpdateObjects = false;

  protected _needsUpdateWorld = false;

  protected _gui: GUI = new GUI();

  protected _isStarted = false;

  protected _stats = new Stats();

  public fixedUpdate(): void {
    if (!this._isStarted) {
      this._isStarted = true;
      this.makeGUI();
      this.updateObjects();
    }

    if (this._needsUpdateObjects) {
      this._needsUpdateObjects = false;
      this.updateObjects();
    }

    if (this._needsUpdateWorld) {
      this._needsUpdateWorld = false;
      this.updateWorld();
    }
  }

  update() {
    super.update();
    this._stats.update();
  }

  protected updateWorld(): void {
    this._ctx.getModule(PhysicsModule).getEngine().setWorldGravity(new Three.Vector3(0, this.gravity, 0));
  }

  protected updateObjects(): void {
    this._objects.forEach((object) => {
      this.destroyObject(object);
    });
    this._objects = [];

    for (let i = 0; i < this.objectsCount; i++) {
      const object = this.makeObject();
      this.entity.object.add(object.object);
      this._objects.push(object);
    }
  }

  protected makeObject(): Entity {
    const objectMesh = new Three.Mesh();
    const objectEntity = this.entityManager.makeEntity(objectMesh);

    switch (this.objectGeometry) {
      case 'box':
        objectMesh.geometry = new Three.BoxGeometry(1, 1, 1);
        break;
      case 'torus':
        objectMesh.geometry = new Three.TorusGeometry(0.3, 0.2, 20, 20);
        break;
      case 'capsule':
        objectMesh.geometry = new Three.CapsuleGeometry(0.5, 1);
        break;
      case 'teapot':
        objectMesh.geometry = new TeapotGeometry(0.5);
    }

    objectMesh.material = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#ea5858') });
    objectMesh.name = 'box';

    const rigidBody = objectEntity.addComponent(RigidBodyComponent);
    rigidBody.type = RigidBodyType.Dynamic;
    rigidBody.restitution = this.objectRestitution;

    switch (this.colliderShape) {
      case 'box':
        objectEntity.addComponent(BoxColliderComponent);
        break;
      case 'convexHull':
        objectEntity.addComponent(ConvexHullColliderComponent);
        break;
      case 'capsule':
        objectEntity.addComponent(CapsuleColliderComponent);
        break;
      case 'triangleMesh':
        objectEntity.addComponent(TriangleMeshColliderComponent);
        break;
    }

    objectMesh.position.x = Math.random() * 10 - 5;
    objectMesh.position.y = Math.random() * 10 + 5;
    objectMesh.position.z = Math.random() * 10 - 5;

    return objectEntity;
  }

  protected destroyObject(entity: Entity): void {
    entity.destroy();
  }

  protected makeGUI(): void {
    const objectsFolder = this._gui.addFolder('Objects');
    objectsFolder.add(this, 'objectsCount', 0, 1000, 1)
      .name('Objects count')
      .onChange(this.scheduleUpdateObjects.bind(this));
    objectsFolder.add(this, 'objectGeometry', ['box', 'torus', 'capsule', 'teapot'])
      .name('Object geometry')
      .onChange(this.scheduleUpdateObjects.bind(this));
    objectsFolder.add(this, 'colliderShape', ['box', 'convexHull', 'capsule', 'triangleMesh'])
      .name('Collider shape')
      .onChange(this.scheduleUpdateObjects.bind(this));
    objectsFolder.add(this, 'objectRestitution', 0, 1, 0.1)
      .name('Object restitution')
      .onChange(this.scheduleUpdateObjects.bind(this));

    const worldFolder = this._gui.addFolder('World');
    worldFolder.add(this, 'gravity', -20, 20, 0.1)
      .name('Gravity')
      .onChange(this.scheduleUpdateWorld.bind(this));

    const engineFolder = this._gui.addFolder('Engine');
    engineFolder.add(this, 'debuggerIsActive')
      .name('Debugger enabled')
      .onChange(this.updateDebugger.bind(this));

    engineFolder.add(this, 'fixedStepsCount', 0, 120, 1)
      .name('Fixed steps count')
      .onChange(this.updateFixedStepsCount.bind(this));

    this._gui.add(this, 'scheduleUpdateObjects')
      .name('Reset world');

    document.body.appendChild(this._stats.dom);
  }

  protected scheduleUpdateObjects(): void {
    this._needsUpdateObjects = true;
  }

  protected scheduleUpdateWorld(): void {
    this._needsUpdateWorld = true;
  }

  protected updateDebugger(): void {
    this._ctx.getModule(PhysicsModule).getEngine().setDebuggerIsActive(this.debuggerIsActive);
  }

  protected updateFixedStepsCount(): void {
    this._ctx.getModule(TimeModule).time.fixedTimeDelta = this.fixedStepsCount === 0 ? 0 : 1 / this.fixedStepsCount;
  }
}
