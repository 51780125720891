import * as Three from 'three';
import { BoxColliderComponent, Entity, RigidBodyComponent, RigidBodyType } from '@own/engine';
import { MyScene } from '../MyScene';

export const buildCubes = (scene: MyScene): Entity[] => {
  const cubes: Entity[] = [];

  for (let i = 0; i < 1; i++) {
    const geometry = new Three.BoxGeometry(1, 1, 1);
    const material = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#ff0000') });
    const cube = scene.entityManager.makeEntity(new Three.Mesh(geometry, material));

    cube.object.position.set(
      0.1,
      10,
      0.1,
    );

    cube.addComponent(RigidBodyComponent, {
      mass: 1,
      type: RigidBodyType.Dynamic,
    });

    cube.addComponent(BoxColliderComponent, {
      size: new Three.Vector3(1, 1, 1),
    });

    cubes.push(cube);
  }

  return cubes;
};
