import * as Three from 'three';
import { Pane } from 'tweakpane';
import {
  Scene,
  ImageAsset,
  RenderModule,
  OrbitalLockToTargetFollow,
  CameramanBrainComponent,
  CameramanVCameraCollider,
  CameramanVCameraComponent,
  PhysicsModule, PerspectiveCameraComponent, Component,
} from '@own/engine';
import * as assetsUrls from './assetsUrls';
import { buildFloor, buildLights } from './builders';
import { buildWalls } from './builders/buildWalls';
import { OrbitControllerScript } from '../../packages';

export class MyScene extends Scene {
  public pane: Pane = new Pane();

  public autorotateTimer: number = 0;

  public autorotate: boolean = false;

  public prepareAssets() {
    this.assetBundle.registerAsset({ path: assetsUrls.prototypeTileImage }, ImageAsset);
  }

  public buildScene() {
    const entity = this.entityManager.makeEntity();
    entity.addComponent(Component);

    const entity2 = this.entityManager.makeEntity(new Three.Object3D());
    entity2.addComponent(Component);

    entity.object.add(entity2.object);

    entity2.object.add(new Three.Mesh(new Three.BoxGeometry(1, 1, 1), new Three.MeshBasicMaterial({ color: 0xff0000 })));


    this.pane.element.parentElement!.style.width = '300px';

    const camera = this.entityManager.makeEntity(new Three.PerspectiveCamera());
    camera.addComponent(PerspectiveCameraComponent);
    camera.addComponent(CameramanBrainComponent);
    camera.object.position.set(0, 1, 10);
    camera.object.lookAt(new Three.Vector3(0, 1, 0));

    const target = this.entityManager.makeEntity(new Three.Mesh());
    const targetMesh = target.getObjectAs(Three.Mesh);
    targetMesh.geometry = new Three.SphereGeometry(0.1);
    targetMesh.material = new Three.MeshBasicMaterial({ color: 0xff0000, wireframe: true });
    targetMesh.position.set(0, 1, 0);
    this.add(targetMesh);

    const vCameraPatient = this.entityManager.makeEntity(new Three.Object3D());
    vCameraPatient.addComponent(OrbitControllerScript, {
      isActive: false,
      inputsAreActive: false,
      minRadius: 0.1,
      initialPhi: 1.598,
      initialTheta: 3.077,
      rotationDamping: 0.9,
      initialRadius: 2.8,
      debugVCamera: true,
      maxPhi: Math.PI * 0.9,
      target,
    });
    vCameraPatient.object.name = 'patientControls';
    this.add(vCameraPatient.object);
    vCameraPatient.addComponent(CameramanVCameraCollider, {
      debug: true,
    });

    const orbitControls = this.entityManager.makeEntity(new Three.Object3D());
    orbitControls.object.name = 'sceneControls';
    orbitControls.addComponent(OrbitControllerScript, {
      maxPhi: Math.PI,
      minPhi: 0,
      minRadius: 0.1,
      maxRadius: 10,
      radiusSpeed: 0.5,
      initialPhi: Math.PI / 4,
    });

    this.add(vCameraPatient.object);

    this.add(buildFloor(this).object);
    this.add(...buildLights());
    this.add(...buildWalls(this).map((e) => e.object));
    this.add(camera.object);
    this.add(orbitControls.object);

    this.setupRenderer();
    this.setupPane();
    this.toggleDebuggerObject();
  }

  public destroy(): void {
    super.destroy();
    this.pane?.dispose();
  }

  public setupRenderer() {
    this._ctx.getModule(RenderModule).renderer.webglRenderer.toneMapping = Three.ReinhardToneMapping;
    this._ctx.getModule(RenderModule).renderer.webglRenderer.toneMappingExposure = 2.3;
    this._ctx.getModule(RenderModule).renderer.webglRenderer.shadowMap.enabled = true;
  }

  protected setupPane() {
    this.pane.addButton({ title: 'Toggle debugger' }).on('click', () => {
      this.toggleDebuggerObject();
    });
    const sceneOrbitObject = this.getObjectByName('sceneControls') as Three.Object3D;
    const patientOrbitObject = this.getObjectByName('patientControls') as Three.Object3D;

    const sceneControlsScript = this.entityManager.getEntity(sceneOrbitObject).getComponent(OrbitControllerScript);
    const patientControlsScript = this.entityManager.getEntity(patientOrbitObject).getComponent(OrbitControllerScript);

    const patientFollow = patientControlsScript.entity
      .getComponent(CameramanVCameraComponent)
      .getFollowAs(OrbitalLockToTargetFollow);

    this.pane.addBinding(patientFollow, 'phi', {
      min: Math.PI / 18,
      max: Math.PI * 0.99,
      step: Math.PI / 1800,
    });

    this.pane.addBinding(patientFollow, 'theta', {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 1800,
    });

    this.pane.addBinding(patientFollow, 'radius', {
      min: 0.1,
      max: 10,
      step: 0.01,
    });

    this.pane.addButton({ title: 'toggle camera' }).on('click', () => {
      patientControlsScript.isActive = !patientControlsScript.isActive;
      patientControlsScript.inputsAreActive = !patientControlsScript.inputsAreActive;

      sceneControlsScript.isActive = !sceneControlsScript.isActive;
      sceneControlsScript.inputsAreActive = !sceneControlsScript.inputsAreActive;
    });

    this.pane.addButton({ title: 'toggle target' }).on('click', () => {
      sceneControlsScript.target = sceneControlsScript.target ? undefined : patientControlsScript.entity;
      sceneControlsScript.followOffset.set(0, 0, 0);
      sceneControlsScript.lookAtOffset.set(0, 0, 0);
    });

    this.pane.addBinding(this, 'autorotate').on('change', () => {
      clearInterval(this.autorotateTimer);

      if (!this.autorotate) return;

      this.autorotateTimer = window.setInterval(() => {
        patientFollow.theta += Math.PI / 180 / 10;
      }, 1000 / 60);
    });
  }

  protected toggleDebuggerObject() {
    const debuggerObject = this._ctx.getModule(PhysicsModule).getEngine().getDebuggerObject();

    if (debuggerObject.parent === null) {
      this.add(debuggerObject);
    } else {
      this.remove(debuggerObject);
    }
  }
}
