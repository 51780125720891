import { CharacterControllerScript } from './CharacterController.script';
import { CharacterActionsScript } from './CharacterActions.script';
import * as Three from 'three';
import { RenderModule } from '@own/engine';
import { ScriptComponent, ScriptComponentOptions, ScriptComponentProps } from '@own/engine';
import { MToonMaterial } from '@pixiv/three-vrm';
import { CameramanVCameraComponent } from '@own/engine';

export type CharacterCameraControllerScriptProps = ScriptComponentProps & {
  p1CameraRotationSpeed?: number;
  p3CameraRotationSpeed?: number;
}

/**
 * if rotation speed === Math.Pi / 2 / 1000, it means that it will take 1000px to rotate camera on 90 degrees
 */
export class CharacterCameraControllerScript extends ScriptComponent {
  protected p1CameraRotationSpeed: number = Math.PI / 2 / 1000;

  protected p3CameraRotationSpeed: number = Math.PI / 2 / 900;

  protected p1CameraTouchRotationSpeed: number = Math.PI / 2 / 150;

  protected p3CameraTouchRotationSpeed: number = Math.PI / 2 / 150;

  protected opacityHandlingIsFinished = true;

  constructor(options: ScriptComponentOptions<CharacterCameraControllerScriptProps>) {
    super(options);

    this.p1CameraRotationSpeed = options.props?.p1CameraRotationSpeed ?? this.p1CameraRotationSpeed;
    this.p3CameraRotationSpeed = options.props?.p3CameraRotationSpeed ?? this.p3CameraRotationSpeed;
  }

  protected get actionsMap() {
    return this.characterActionsScript.actionsMap;
  }

  protected get rendererDomElement() {
    return this.characterControllerScript.ctx.getModule(RenderModule).renderer.domElement;
  }

  protected get characterControllerScript(): CharacterControllerScript {
    return this.entity.getComponent(CharacterControllerScript);
  }

  protected get characterActionsScript(): CharacterActionsScript {
    return this.entity.getComponent(CharacterActionsScript);
  }

  public update(): void {
    this.handleCameraModeBlending();

    if (this.characterControllerScript.controllerIsActive) {
      this.handleChangeCameraMode();
      this.handleCameraChanges();
    }
  }

  protected handleChangeCameraMode(): void {
    if (this.characterControllerScript.cameraModeBlendingIsActive) return;
    if (!this.actionsMap.toggleCameraMode.wasPerformedThisFrame) return;

    if (this.characterControllerScript.cameraMode === 'third-person') {
      const p3CameraRotation = this.characterControllerScript.getP3CameraRotation();

      this.characterControllerScript.cameraMode = 'first-person';
      this.characterControllerScript.setP1CameraRotation(new Three.Vector2(p3CameraRotation.x, 0));
    } else {
      const p1CameraRotation = this.characterControllerScript.getP1CameraRotation();

      this.characterControllerScript.setP3CameraDistance(2);
      this.characterControllerScript.setP3CameraRotation(new Three.Vector2(p1CameraRotation.x, 0));
      this.characterControllerScript.cameraMode = 'third-person';
    }
  }

  // todo: think about final blending state
  protected handleCameraModeBlending(): void {
    const { cameraModeBlendingNormalizedTime } = this.characterControllerScript;

    if (cameraModeBlendingNormalizedTime === -1) {
      if (!this.opacityHandlingIsFinished) this.finishAvatarOpacityHandling();
      return;
    }

    const blendingState = this.characterControllerScript.getVCameraBrainComponent()?.activeBlendState;

    const ownCameraComponents = [
      this.characterControllerScript.getP1VCameraComponent(),
      this.characterControllerScript.getP3VCameraComponent(),
    ];

    const fromIsOwn = ownCameraComponents.includes(blendingState?.from as CameramanVCameraComponent);
    const toIsOwn = ownCameraComponents.includes(blendingState?.to as CameramanVCameraComponent);
    const ownFromIsP1 = fromIsOwn && blendingState?.from === this.characterControllerScript.getP1VCameraComponent();
    const ownToIsP1 = toIsOwn && blendingState?.to === this.characterControllerScript.getP1VCameraComponent();

    if (!ownFromIsP1 && !ownToIsP1) return;

    this.opacityHandlingIsFinished = false;

    this.setAvatarOpacity(ownToIsP1
      ?
      1 - cameraModeBlendingNormalizedTime
      : cameraModeBlendingNormalizedTime);
  }

  protected handleCameraChanges(): void {
    if (this.characterControllerScript.cameraModeBlendingIsActive) return;

    if (this.characterControllerScript.cameraMode === 'third-person') {
      this.characterControllerScript.p3CameraDistanceDelta += Math.sign(this.actionsMap.p3CameraDelta.readValue().y);
    }

    if (this.actionsMap.rotationButton.isPerformed() || this.characterActionsScript.pointerLockIsActive) {
      this.characterControllerScript.cameraRotationDelta
        .copy(this.actionsMap.moveDelta.readValue())
        .multiplyScalar(this.getCameraRotationSpeed());
    }
  }

  protected getCameraRotationSpeed(): number {
    if (this.characterControllerScript.cameraMode === 'first-person') {
      return this.isTouchDevice() ? this.p1CameraTouchRotationSpeed : this.p1CameraRotationSpeed;
    } else {
      return this.isTouchDevice() ? this.p3CameraTouchRotationSpeed : this.p3CameraRotationSpeed;
    }
  }

  // todo: need processors on input actions
  protected isTouchDevice(): boolean {
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
  }

  protected finishAvatarOpacityHandling(): void {
    const avatarIsHidden = this.characterControllerScript.cameraMode === 'first-person'
      && this.characterControllerScript.controllerIsActive;
    this.setAvatarOpacity(avatarIsHidden ? 0 : 1);
    this.opacityHandlingIsFinished = true;
  }

  protected setAvatarOpacity(opacity: number): void {
    this.characterControllerScript.entity.object.traverse((object) => {
      if (!(object instanceof Three.Mesh)) return;

      const materials = Array.isArray(object.material) ? object.material : [object.material];

      materials.forEach((material) => {
        if (!(material instanceof Three.Material) && !(material instanceof MToonMaterial)) return;

        material.opacity = opacity;
        material.transparent = true;
        material.needsUpdate = true;
        material.alphaTest = 0.001;
      });
    });
  }
}
