import * as Three from 'three';
import { Scene, GlbAsset, HdrAsset, EnvironmentComponent, Entity, PerspectiveCameraComponent } from '@own/engine';

const helmUrl = 'https://threejs.org/examples/models/gltf/DamagedHelmet/glTF/DamagedHelmet.gltf';
const envUrl = 'https://threejs.org/examples/textures/equirectangular/royal_esplanade_1k.hdr';

export class MyScene extends Scene {
  public prepareAssets() {

    this.assetBundle.registerAsset({ path: helmUrl }, GlbAsset);
    this.assetBundle.registerAsset({ path: envUrl }, HdrAsset);
  }

  public buildScene() {
    const helmAsset = this.assetBundle.getAsset(helmUrl, GlbAsset);

    const perspectiveCameraEntity = this.entityManager.makeEntity(new Three.PerspectiveCamera());
    perspectiveCameraEntity.addComponent(PerspectiveCameraComponent);

    this.add(this.makeEnvironmentController().object);
    this.add(perspectiveCameraEntity.object);
    this.add(helmAsset.makeEntity().object);
  }

  protected makeEnvironmentController(): Entity {
    const envAsset = this.assetBundle.getAsset(envUrl, HdrAsset);
    const envController = this.entityManager.makeEntity(new Three.Object3D());
    const envComponent = envController.addComponent(EnvironmentComponent);

    envComponent.environmentTexture = envAsset.texture;
    envComponent.backgroundTexture = envAsset.texture;

    return envController;
  }
}
