import { CharacterLogicState } from './CharacterLogicState';
import { IdleState } from './IdleState';
import { RunState } from './RunState';
import { CharacterActionsScript } from '../CharacterActions.script';

export class WalkState extends CharacterLogicState {
  protected baseSpeed = 1.65;

  protected get actions(): CharacterActionsScript {
    return this._characterController.entity.getComponent(CharacterActionsScript);
  }

  public enter(dt: number): CharacterLogicState {
    this.getAnimatorParameters().setBooleanParameterValue('isWalking', true);

    this._characterController.moveSpeed = this.baseSpeed;

    return this.update(dt);
  }

  public update(dt: number): CharacterLogicState {
    this._characterController.moveDirection.copy(this.actions.getMoveDirection());

    if (this._characterController.moveDirection.length() === 0) return this.transitionTo(IdleState, dt);

    if (this.actions.actionsMap.sprintButton.isPressed()) return this.transitionTo(RunState, dt);

    return this;
  }

  public exit(dt: number): number {
    this.getAnimatorParameters().setBooleanParameterValue('isWalking', false);

    this._characterController.moveDirection.set(0, 0);
    this._characterController.moveSpeed = 0;

    return dt;
  }
}
