import { create } from 'zustand';

export enum ContextMenuType {
  State = 'State',
  Transition = 'transition',
  Pane = 'pane',
}

export type PaneContextMenu = {
  type: ContextMenuType.Pane;
  isActive: boolean;
  position: { x: number; y: number };
}

export type StateContextMenu = {
  type: ContextMenuType.State;
  isActive: boolean;
  position: { x: number; y: number };
  stateName: string;
}

export type TransitionContextMenu = {
  type: ContextMenuType.Transition;
  isActive: boolean;
  position: { x: number; y: number };
  transitionName: string;
}

export type StateEditPanel = {
  isActive: boolean;
  stateName: string;
}

export type TransitionsEditPanel = {
  isActive: boolean;
  transitionName: string;
}

export type ParameterEditPanel = {
  isActive: boolean;
}

export type EditorStore = {
  paneContextMenu: PaneContextMenu,
  stateContextMenu: StateContextMenu,
  transitionContextMenu: TransitionContextMenu,
  closeContextMenu: () => void;
  openPaneContextMenu: (params: Pick<PaneContextMenu, 'position'>) => void;
  openStateContextMenu: (params: Pick<StateContextMenu, 'position' | 'stateName'>) => void;
  openTransitionContextMenu: (params: Pick<TransitionContextMenu, 'position' | 'transitionName'>) => void;
  stateEditPanel: StateEditPanel;
  transitionsEditPanel: TransitionsEditPanel;
  parameterEditPanel: ParameterEditPanel;
  closeEditPanel: () => void;
  openStateEditPanel: (params: Pick<StateEditPanel, 'stateName'>) => void;
  openTransitionsEditPanel: (params: Pick<TransitionsEditPanel, 'transitionName'>) => void;
  openParameterEditPanel: () => void;
}

// research best practices for zustand
// or change to another state management library
const useEditorStore = create<EditorStore>((set) => ({
  paneContextMenu: {
    isActive: false,
    type: ContextMenuType.Pane,
    position: { x: 0, y: 0 },
  },
  stateContextMenu: {
    isActive: false,
    type: ContextMenuType.State,
    position: { x: 0, y: 0 },
    stateName: '',
  },
  transitionContextMenu: {
    isActive: false,
    type: ContextMenuType.Transition,
    position: { x: 0, y: 0 },
    transitionName: '',
  },
  closeContextMenu: () => {
    set((state) => ({
      paneContextMenu: {
        ...state.paneContextMenu,
        isActive: false,
      },
      stateContextMenu: {
        ...state.stateContextMenu,
        isActive: false,
      },
      transitionContextMenu: {
        ...state.transitionContextMenu,
        transitionName: '',
        isActive: false,
      },
    }));
  },
  openPaneContextMenu: (params) => {
    set((state) => ({
      paneContextMenu: {
        ...state.paneContextMenu,
        ...params,
        isActive: true,
      },
    }));
  },
  openStateContextMenu: (params) => {
    set((state) => ({
      stateContextMenu: {
        ...state.stateContextMenu,
        ...params,
        isActive: true,
      },
    }));
  },
  openTransitionContextMenu: (params) => {
    set((state) => ({
      transitionContextMenu: {
        ...state.transitionContextMenu,
        ...params,
        isActive: true,
      },
    }));
  },
  stateEditPanel: {
    isActive: false,
    stateName: '',
  },
  transitionsEditPanel: {
    isActive: false,
    transitionName: '',
  },
  parameterEditPanel: {
    isActive: true,
  },
  closeEditPanel: () => {
    set((state) => ({
      stateEditPanel: {
        ...state.stateEditPanel,
        stateName: '',
        isActive: false,
      },
      transitionsEditPanel: {
        ...state.transitionsEditPanel,
        transitionName: '',
        isActive: false,
      },
      parameterEditPanel: {
        ...state.parameterEditPanel,
        isActive: false,
      },
    }));
  },
  openStateEditPanel: (params) => {
    set((state) => ({
      stateEditPanel: {
        ...state.stateEditPanel,
        ...params,
        isActive: true,
      },
    }));
  },
  openTransitionsEditPanel: (params) => {
    set((state) => ({
      transitionsEditPanel: {
        ...state.transitionsEditPanel,
        ...params,
        isActive: true,
      },
    }));
  },
  openParameterEditPanel: () => {
    set((state) => ({
      parameterEditPanel: {
        ...state.parameterEditPanel,
        isActive: true,
      },
    }));
  },
}));

export { useEditorStore };
