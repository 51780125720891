import { FC, useCallback } from 'react';
import { Button, Popover, PopoverBody, PopoverContent, useToast, VStack } from '@chakra-ui/react';
import { useEngine } from '../../hooks';
import { useEditorStore } from '../../store';

export const StateFlowNodeMenu: FC = () => {
  const { getAnyAnimationController } = useEngine();
  const { closeContextMenu, stateContextMenu } = useEditorStore();
  const toast = useToast();

  const handleDeleteClick = useCallback(() => {
    const { rootStateMachine } = getAnyAnimationController();
    const state = rootStateMachine.states.find((state) => state.name === stateContextMenu.stateName);

    if (!state) throw new Error('Invalid state');

    try {
      closeContextMenu();
      rootStateMachine.removeState(state.name);
    } catch (error) {
      toast({
        title: 'State deletion error.',
        description: error instanceof Error ? error.message : 'Unknown error.',
        status: 'error',
        duration: 4000,
        position: 'top',
        isClosable: true,
      });
    }
  }, [closeContextMenu, getAnyAnimationController, stateContextMenu.stateName, toast]);

  return <div style={{ position: 'absolute', left: stateContextMenu.position.x, top: stateContextMenu.position.y }}>
    <Popover isOpen={stateContextMenu.isActive}>
      <PopoverContent width="auto">
        <PopoverBody>
          <VStack>
            <Button
              colorScheme="red"
              size="sm"
              onClick={handleDeleteClick}
              width={120}
            >
              Delete
            </Button>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  </div>;
};
