import * as Three from 'three';
import { Pane } from 'tweakpane';
import {
  Scene,
  CapsuleColliderComponent,
  RigidBodyComponent,
  RigidBodyType,
  StaticPlaneColliderComponent,
  CameramanBrainComponent,
  MainLoopModule,
  PerspectiveCameraComponent, Entity,
} from '@own/engine';
import { MoveDummyScript } from './scripts/MoveDummy.script';
import { ControlledDummyScript } from './scripts/ControlledDummy.script';
import { OrbitControllerScript } from '../../packages';

export class MyScene extends Scene {
  protected pane?: Pane;

  public buildScene() {
    const camera = this.entityManager.makeEntity(new Three.PerspectiveCamera());
    const light = new Three.DirectionalLight(0xffffff, 3);
    light.position.set(1, 1, 1).normalize();

    camera.addComponent(PerspectiveCameraComponent);

    const [
      capsule,
      dummy1,
      dummy2,
      capsuleOrbitControllerComponent,
      dummy1OrbitControllerComponent,
      dummy2OrbitControllerComponent,
    ] = this.makeControllers();

    this.makeControlPanel(
      camera.addComponent(CameramanBrainComponent),
      capsuleOrbitControllerComponent,
      dummy1OrbitControllerComponent,
      dummy2OrbitControllerComponent,
    );

    this.add(capsule.object);
    this.add(camera.object);
    this.add(light);
    this.add(dummy1.object);
    this.add(dummy2.object);
    this.add(capsuleOrbitControllerComponent.entity.object);
    this.add(dummy1OrbitControllerComponent.entity.object);
    this.add(dummy2OrbitControllerComponent.entity.object);
    this.add(this.makePlane().object);
    this.add(new Three.AxesHelper(5));
  }

  protected makeMoveDummy(xOffset: number): Entity {
    const entity = this.entityManager.makeEntity(new Three.Mesh());
    const mesh = entity.getObjectAs(Three.Mesh);

    const sphereGeometry = new Three.SphereGeometry(0.5, 8, 8);
    const sphereMaterial = new Three.MeshBasicMaterial({ color: Math.floor(Math.random() * 0xffffff), wireframe: true });
    mesh.geometry = sphereGeometry;
    mesh.material = sphereMaterial;
    entity.addComponent(MoveDummyScript, { xOffset });

    return entity;
  }

  protected makeCapsule(): Entity {
    const entity = this.entityManager.makeEntity(new Three.Mesh());
    const mesh = entity.getObjectAs(Three.Mesh);

    const sphereGeometry = new Three.CapsuleGeometry(0.5, 1, 32, 32);
    const sphereMaterial = new Three.MeshStandardMaterial({ color: Math.floor(Math.random() * 0xffffff) });
    mesh.position.set(0, 5.6, 0);
    mesh.geometry = sphereGeometry;
    mesh.material = sphereMaterial;
    entity.addComponent(RigidBodyComponent, { type: RigidBodyType.Dynamic, mass: 20 });
    entity.addComponent(CapsuleColliderComponent, { radius: 0.5, height: 1 });
    entity.addComponent(ControlledDummyScript, { speed: 5 });

    return entity;
  }

  protected makePlane(): Entity {
    const geometry = new Three.PlaneGeometry(10, 10);
    geometry.rotateX(Math.PI / 2);
    const material = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#5f91c4'), side: Three.DoubleSide });
    const plane = this.entityManager.makeEntity(new Three.Mesh());
    const planeMesh = plane.getObjectAs(Three.Mesh);

    planeMesh.name = 'floor';

    planeMesh.geometry = geometry;
    planeMesh.material = material;

    const rb = plane.addComponent(RigidBodyComponent);
    rb.type = RigidBodyType.Static;
    rb.mass = 0;
    plane.addComponent(StaticPlaneColliderComponent);


    return plane;
  }

  protected makeControllers(): [Entity, Entity, Entity, OrbitControllerScript, OrbitControllerScript, OrbitControllerScript] {
    const dummy1 = this.makeMoveDummy(-2);
    const dummy2 = this.makeMoveDummy(2);
    const capsule = this.makeCapsule();

    const capsuleOrbitController = this.entityManager.makeEntity()
    const capsuleOrbitControllerComponent = capsuleOrbitController.addComponent(OrbitControllerScript, {
      target: capsule,
      isActive: true,
      fov: 70,
    });
    capsuleOrbitController.object.name = 'capsule';

    const dummy1OrbitController = this.entityManager.makeEntity()
    const dummy1OrbitControllerComponent = dummy1OrbitController.addComponent(OrbitControllerScript, {
      target: dummy1,
      isActive: false,
      fov: 20,
    });
    dummy1OrbitController.object.name = 'dummy1';

    const dummy2OrbitController = this.entityManager.makeEntity()
    const dummy2OrbitControllerComponent = dummy2OrbitController.addComponent(OrbitControllerScript, {
      target: dummy2,
      isActive: false,
      fov: 120,
    });
    dummy2OrbitController.object.name = 'dummy2';

    return [
      capsule,
      dummy1,
      dummy2,
      capsuleOrbitControllerComponent,
      dummy1OrbitControllerComponent,
      dummy2OrbitControllerComponent,
    ];
  }

  protected makeControlPanel(
    brainComponent: CameramanBrainComponent,
    capsuleOrbitControllerComponent: OrbitControllerScript,
    dummy1OrbitControllerComponent: OrbitControllerScript,
    dummy2OrbitControllerComponent: OrbitControllerScript,
  ): void {
    this.pane = new Pane();
    this.pane.element.parentElement!.style.width = '300px';

    const paneState = {
      activeOrbitControllerComponent: capsuleOrbitControllerComponent,
      activeOrbitControllerName: capsuleOrbitControllerComponent.entity.object.name,
      brainComponent: brainComponent,
      offset: new Three.Vector3(),
    };

    this.pane.addBinding(paneState, 'activeOrbitControllerName', {
      label: 'Active VCam',
      options: {
        capsule: 'capsule',
        dummy1: 'dummy1',
        dummy2: 'dummy2',
      },
    }).on('change', (e) => {
      capsuleOrbitControllerComponent.isActive = false;
      dummy1OrbitControllerComponent.isActive = false;
      dummy2OrbitControllerComponent.isActive = false;

      if (e.value === 'capsule') {
        capsuleOrbitControllerComponent.isActive = true;
        paneState.activeOrbitControllerComponent = capsuleOrbitControllerComponent;
        paneState.offset.copy(capsuleOrbitControllerComponent.followOffset);
      }
      if (e.value === 'dummy1') {
        dummy1OrbitControllerComponent.isActive = true;
        paneState.activeOrbitControllerComponent = dummy1OrbitControllerComponent;
        paneState.offset.copy(dummy1OrbitControllerComponent.followOffset);
      }
      if (e.value === 'dummy2') {
        dummy2OrbitControllerComponent.isActive = true;
        paneState.activeOrbitControllerComponent = dummy2OrbitControllerComponent;
        paneState.offset.copy(dummy2OrbitControllerComponent.followOffset);
      }
    });

    this.pane.addBinding(paneState.brainComponent, 'blendTime', {
      label: 'Blend time',
      step: 0.1,
      min: 0,
      max: 5,
    });

    this.pane.addBinding(paneState, 'offset', { label: 'Offset' })
      .on('change', (e) => {
        paneState.activeOrbitControllerComponent.followOffset.copy(e.value);
        paneState.activeOrbitControllerComponent.lookAtOffset.copy(e.value);
      });

    this._ctx.getModule(MainLoopModule).mainLoop.events.updateEnd.addListener(() => {
      paneState.offset.copy(paneState.activeOrbitControllerComponent.followOffset);
      this.pane?.refresh();
    });
  }

  public destroy(): void {
    super.destroy();
    this.pane?.dispose();
  }
}
