import { CharacterLogicState } from './CharacterLogicState';
import { WalkState } from './WalkState';
import { CharacterActionsScript } from '../CharacterActions.script';

export class IdleState extends CharacterLogicState {
  protected get actions(): CharacterActionsScript {
    return this._characterController.entity.getComponent(CharacterActionsScript);
  }

  public update(dt: number): CharacterLogicState {
    if (!this._characterController.controllerIsActive) return this;

    const moveDirection = this.actions.getMoveDirection();

    if (moveDirection.length() !== 0) {
      return this.transitionTo(WalkState, dt);
    }

    return this;
  }
}
