import * as Three from 'three';
import { FC, useCallback, useMemo } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverBody, PopoverContent } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { GlbAssetProvider } from '@own/engine';
import * as ReactFlow from 'reactflow';
import { useEngine } from '../../hooks';
import { useEditorStore } from '../../store';

export const FlowPaneMenu: FC = () => {
  const { project } = ReactFlow.useReactFlow();
  const { getAnyAnimationController, assetManager } = useEngine();
  const { paneContextMenu, closeContextMenu } = useEditorStore();

  const clips = useMemo(() => {
    return assetManager.assetProviders.reduce<Three.AnimationClip[]>((clips, assetProvider) => {
      if (!assetProvider.isLoaded) return clips;
      if (!(assetProvider instanceof GlbAssetProvider)) return clips;

      return [
        ...assetProvider.getAsset().getAnimations()
          .filter((clip) => !clips.find((c) => c.name === clip.name))
          .filter((clip) => !getAnyAnimationController().rootStateMachine.states.find((state) => state.name === clip.name)),
        ...clips,
      ];
    }, []);
  }, [assetManager.assetProviders, getAnyAnimationController]);

  const handleAnimationClipClick = useCallback((clip: Three.AnimationClip) => {
    const existAnimation = getAnyAnimationController().rootStateMachine.states.find((state) => state.name === clip.name);

    if (existAnimation) throw new Error(`Animation ${clip.name} already exists`);

    const state = getAnyAnimationController().rootStateMachine.addState(clip.name);
    state.setMotion(clip);
    const projectedPosition = project({ x: paneContextMenu.position.x, y: paneContextMenu.position.y });
    state.position.set(projectedPosition.x, projectedPosition.y);

    closeContextMenu();
  }, [getAnyAnimationController, project, paneContextMenu.position.x, paneContextMenu.position.y, closeContextMenu]);

  return <div style={{ position: 'absolute', left: paneContextMenu.position.x, top: paneContextMenu.position.y }}>
    <Popover isOpen={paneContextMenu.isActive}>
      <PopoverContent width="auto">
        <PopoverBody>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              isDisabled={clips.length === 0}
            >
              {clips.length ? 'Add state' : 'No clips'}
            </MenuButton>
            <MenuList>
              {clips.map((clip) => <MenuItem
                onClick={() => handleAnimationClipClick(clip)}
                key={clip.name}
              >
                {clip.name}
              </MenuItem>)}
            </MenuList>
          </Menu>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  </div>;
};
