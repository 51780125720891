import { FC, useState } from 'react';
import { useEngine } from '../../hooks';
import { Button, HStack, IconButton } from '@chakra-ui/react';
import { FaPlay, FaPause, FaStop } from 'react-icons/fa6';
import { SettingsIcon } from '@chakra-ui/icons';
import { useEditorStore } from '../../store';


export const PlayController: FC = () => {
  const { getAnyAnimationController } = useEngine();
  const { closeEditPanel, openParameterEditPanel } = useEditorStore();
  const controller = getAnyAnimationController();
  const [isPlaying, setIsPlaying] = useState(true);
  const [isStopped, setIsStopped] = useState(false);

  return (
    <HStack padding={1}>
      <IconButton
        isDisabled={isPlaying}
        aria-label="play"
        icon={<FaPlay />}
        onClick={() => {
          controller.resume();
          setIsStopped(false);
          setIsPlaying(true);
        }}
      />
      <IconButton
        aria-label="pause"
        isDisabled={!isPlaying}
        icon={<FaPause />}
        onClick={() => {
          controller.pause();
          setIsPlaying(false);
        }}
      />
      <IconButton
        isDisabled={isStopped}
        aria-label="reset"
        icon={<FaStop />}
        onClick={() => {
          controller.reset();
          controller.pause();
          setIsPlaying(false);
          setIsStopped(true);
        }}
      />
      <Button
        colorScheme="blue"
        leftIcon={<SettingsIcon />}
        onClick={() => {
          closeEditPanel();
          openParameterEditPanel();
        }}
      >
        Parameters
      </Button>
    </HStack>
  );
};
