export const mixamoModel = '/mixamo/mixamo_y_bot.fbx';

export const vrmModel = '/mirafinal.vrm';

export const mixamoWalkAnimation = '/mixamo/mixamo_walking.fbx';

export const mixamoIdleAnimation = '/mixamo/mixamo_idle.fbx';

export const mixamoRunAnimation = '/mixamo/mixamo_fast_run.fbx';

export const prototypeTileImage = '/protoTextures/prototype_512x512_grey1.png';
