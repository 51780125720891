import * as ReactFlow from 'reactflow';
import { FC, useCallback, useMemo } from 'react';
import classNames from './StateFlowNode.module.css';
import { Progress, Text, VStack, useTheme, Theme, Flex } from '@chakra-ui/react';
import { useEditorStore } from '../../store';

export type StateFlowNode = ReactFlow.NodeProps<{
  motionNormalizedTime: number;
}>;

const StateFlowNode: FC<StateFlowNode> = (props: ReactFlow.NodeProps) => {
  const { id: nodeId, data } = props;
  const theme = useTheme<Theme>();
  const { stateEditPanel } = useEditorStore();
  const nodeIsEdit = stateEditPanel.stateName === nodeId;

  const reactFlowStoreSelector = useCallback((state: ReactFlow.ReactFlowState) => {
    const {
      connectionNodeId,
      edges,
      connectionStartHandle,
      connectionEndHandle,
    } = state;

    return {
      connectingIsActive: !!connectionNodeId,
      alreadyConnectedByActiveConnection: !!edges.find((edge) => edge.source === connectionNodeId && edge.target === nodeId),
      currentNodeIsHoveredByConnection: connectionEndHandle?.nodeId === nodeId && connectionStartHandle?.nodeId !== nodeId,
    };
  }, [nodeId]);

  const {
    alreadyConnectedByActiveConnection,
    currentNodeIsHoveredByConnection,
    connectingIsActive,
  } = ReactFlow.useStore(reactFlowStoreSelector);

  const borderColor = useMemo(() => {
    if (nodeIsEdit) return theme.colors.pink['500'];

    if (!connectingIsActive || alreadyConnectedByActiveConnection) return theme.colors.blue['800'];
    if (currentNodeIsHoveredByConnection) return theme.colors.blue['500'];

    return theme.colors.blue['200'];
  }, [
    nodeIsEdit,
    theme.colors.pink,
    theme.colors.blue,
    connectingIsActive,
    alreadyConnectedByActiveConnection,
    currentNodeIsHoveredByConnection,
  ]);

  const backgroundColor = useMemo(() => {
    if (!connectingIsActive) return theme.colors.gray['50'];

    if (alreadyConnectedByActiveConnection) return theme.colors.gray['500'];
    if (currentNodeIsHoveredByConnection) return theme.colors.gray['200'];

    return theme.colors.gray['50'];
  }, [connectingIsActive, theme.colors.gray, alreadyConnectedByActiveConnection, currentNodeIsHoveredByConnection]);

  return (
    <VStack
      className="StateFlowNode"
      width="200px"
      height="200px"
      border="7px solid white"
      position="relative"
      overflow="hidden"
      borderRadius="5px"
      transition="border-color 0.1s ease"
      style={{ borderColor }}
    >
      <Progress
        width="100%"
        height="100%"
        position="absolute"
        backgroundColor={backgroundColor}
        value={data.animationProgress * 100}
      />
      {/* node IN*/}
      <ReactFlow.Handle
        className={classNames.targetHandle}
        position={ReactFlow.Position.Left}
        type="target"
        isConnectableStart={connectingIsActive}
        isConnectableEnd={!alreadyConnectedByActiveConnection}
      />
      <Flex
        position="relative"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text
          fontSize="3xl"
          fontWeight="bold"
          textAlign="center"
          flexGrow={0}
        >
          {props.id}
        </Text>
        <Text
          fontSize="3xl"
          textAlign="center"
          flexGrow={0}
        >
          {data.isActive ? 'active' : '___'}
        </Text>
      </Flex>
      {/* node OUT*/}
      {<ReactFlow.Handle
        className={classNames.sourceHandle}
        position={ReactFlow.Position.Right}
        type="source"
        style={{ opacity: connectingIsActive ? 0 : 1 }}
      />}
    </VStack>
  );
};

export default StateFlowNode;
