import { FC, useCallback, useEffect, useState } from 'react';
import { Bootloader } from '@own/engine';
import { MyScene } from './MyScene';
import { RenderModule, ApplicationModule } from '@own/engine';
import { ReactFlowProvider } from 'reactflow';
import { Box, Fade, Flex, HStack, Spinner } from '@chakra-ui/react';
import { EngineProvider } from './context';
import { StateFlowNodeMenu } from './components/StateFlowNodeMenu';
import { FlowPaneMenu } from './components/FlowPaneMenu';
import { FlowCanvas } from './components/FlowCanvas';
import { StateEditPanel } from './components/StateEditPanel';
import { TransitionsEditPanel } from './components/TransitionsEditPanel';
import { TransitionFlowNodeMenu } from './components/TransitionFlowNodeMenu';
import { PlayController } from './components/PlayConroller';
import { ParametersEditPanel } from './components/ParametersEditPanel';

export const AnimationExample: FC = () => {
  const [app, setApp] = useState<ApplicationModule>();
  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);

  useEffect(() => {
    let isInitialized = false;
    let isDestroyed = false;
    let app: ApplicationModule | undefined;

    const makeApp = async () => {
      app = await new Bootloader().makeApp();

      setApp(app);
      await app.runScene(MyScene);
      setSceneIsLoaded(true);

      isInitialized = true;
      if (isDestroyed) app.destroyContext();
    };

    makeApp().catch((e) => {
      console.error(e);
    });

    return () => {
      isDestroyed = true;
      if (isInitialized && app) app.destroyContext();
      setApp(undefined);
      setSceneIsLoaded(false);
    };
  }, []);

  const engineCanvasRef = useCallback((node: HTMLDivElement) => {
    if (!node || !app) return;

    node.appendChild(app.context.getModule(RenderModule).renderer.webglRenderer.domElement);
  }, [app]);

  const isReady = sceneIsLoaded && !!app;

  return (
    <Box height="100vh">
      <Flex
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner size="xl" />
      </Flex>
      <Fade
        in={isReady}
        style={{ height: '100%' }}
      >
        {isReady && <EngineProvider context={app.context}>
          <ReactFlowProvider>
            <HStack
              gap={0}
              height="100%"
              width="100%"
              backgroundColor="white"
              position="relative"
            >
              <Box
                height="100%"
                width="400px"
                flexShrink={0}
                flexGrow={0}
                ref={engineCanvasRef}
              />
              <Box
                height="100%"
                flexShrink={1}
                flexGrow={1}
                overflow="hidden"
                position="relative"
              >
                <FlowCanvas />
                <StateFlowNodeMenu />
                <FlowPaneMenu />
                <TransitionFlowNodeMenu />
                <Box
                  position="absolute"
                  right={0}
                  top={0}
                >
                  <PlayController />
                </Box>
              </Box>
              <Box
                height="100%"
                width="300px"
                flexGrow={0}
                flexShrink={0}
                overflow="auto"
              >
                <StateEditPanel />
                <TransitionsEditPanel />
                <ParametersEditPanel />
              </Box>
            </HStack>
          </ReactFlowProvider>
        </EngineProvider>}
      </Fade>
    </Box>);
};
