import {
  KeyboardDevice,
  InputActionVector2,
  MouseDevice,
  MouseDeviceLayout,
  TouchscreenDevice,
  TouchscreenDeviceLayout,
  ScriptComponent,
  InputModule,
  InputActionNumber,
  InputActionType,
  KeyboardDeviceLayout,
} from '@own/engine';
import GUI from 'lil-gui';

export class InputExampleScript extends ScriptComponent {
  declare protected buttonAction: InputActionNumber;

  declare protected mouseDeltaAction: InputActionVector2;

  protected _gui?: GUI;

  protected get keyboardLayout(): KeyboardDeviceLayout {
    return this._ctx.getModule(InputModule).inputDeviceLayoutManager.getLayout(KeyboardDeviceLayout);
  }

  protected get mouseLayout(): MouseDeviceLayout {
    return this._ctx.getModule(InputModule).inputDeviceLayoutManager.getLayout(MouseDeviceLayout);
  }

  protected get touchscreenLayout(): TouchscreenDeviceLayout {
    return this._ctx.getModule(InputModule).inputDeviceLayoutManager.getLayout(TouchscreenDeviceLayout);
  }

  protected get keyboardDevice(): KeyboardDevice {
    return this._ctx.getModule(InputModule).inputDeviceManager.getDevice(KeyboardDevice);
  }

  protected get mouseDevice(): MouseDevice {
    return this._ctx.getModule(InputModule).inputDeviceManager.getDevice(MouseDevice);
  }

  protected get touchscreenDevice(): TouchscreenDevice {
    return this._ctx.getModule(InputModule).inputDeviceManager.getDevice(TouchscreenDevice);
  }

  protected awake(): void {
    // declare
    this.buttonAction = this.inputActionManager.addInputAction(InputActionNumber, {
      name: 'button',
      type: InputActionType.Button,
    });

    this.mouseDeltaAction = this.inputActionManager.addInputAction(InputActionVector2, {
      name: 'mouseDelta',
      type: InputActionType.Value,
    });

    // bindings
    this.buttonAction.addBinding(this.keyboardLayout.KeyQ);
    this.buttonAction.addBinding(this.keyboardLayout.KeyE);
    this.buttonAction.addBinding(this.mouseLayout.middleButton);
    this.buttonAction.addBinding(this.mouseLayout.leftButton);
    this.mouseDeltaAction.addBinding(this.mouseLayout.delta);
    this.mouseDeltaAction.addBinding(this.touchscreenLayout.primaryTouch.delta);

    // event system
    this.buttonAction.events.started.addListener(({ action }) => {
      console.log('started', `${action.activeInputControl?.rootParent?.name}: ${action.activeInputControl?.name}`);
    });
    this.buttonAction.events.waiting.addListener(({ action }) => {
      console.log('waiting', `${action.activeInputControl?.rootParent?.name}: ${action.activeInputControl?.name}`);
    });
    this.buttonAction.events.cancelled.addListener(({ action }) => {
      console.log('cancelled', `${action.activeInputControl?.rootParent?.name}: ${action.activeInputControl?.name}`);
    });
    this.buttonAction.events.performed.addListener(({ action }) => {
      console.log('performed', `${action.activeInputControl?.rootParent?.name}: ${action.activeInputControl?.name}`);
    });

  }

  update() {
    super.update();

    if (!this._gui) this.makeGUI();

    // device read api
    if (this.keyboardDevice.getKeyControl('KeyQ').wasPressedThisFrame) console.log('KeyQ wasPressedThisFrame');
    if (this.keyboardDevice.getKeyControl('KeyW').wasPressedThisFrame) console.log('KeyW wasPressedThisFrame');
    if (this.keyboardDevice.getKeyControl('KeyE').wasPressedThisFrame) console.log('KeyE wasPressedThisFrame');
    if (this.mouseDevice.middleButton.wasPressedThisFrame) console.log('middleButton wasPressedThisFrame');

    if (this.touchscreenDevice.primaryTouch.press.wasPressedThisFrame) console.log('touch wasPressedThisFrame');
    if (this.touchscreenDevice.primaryTouch.press.value) console.log('touch delta ', this.touchscreenDevice.primaryTouch.delta.value);
    if (this.touchscreenDevice.primaryTouch.press.wasReleasedThisFrame) console.log('touch wasReleasedThisFrame');

    // action read api
    if (this.buttonAction.wasPerformedThisFrame) console.log('button action wasPerformedThisFrame');
    if (this.mouseDeltaAction.readValue().length() > 100) console.log('mouse delta greater than 100');
  }

  protected makeGUI(): void {
    this._gui = new GUI();
    this._gui.add({
      enablePointerLock: () => {
        this.mouseDevice.requestPointerLock();
      },
    }, 'enablePointerLock');
  }
}
