import * as Three from 'three';
import {
  Scene,
  FbxAsset,
  GlbAsset,
  VRM_AVATAR_TYPE,
  VrmAsset,
  VrmComponent,
  Entity,
  AnimatorComponent,
  AnimatorController,
  MIXAMO_AVATAR_TYPE,
  CUSTOM_AVATAR_TYPE,
  RPM_AVATAR_TYPE,
  T_POSE_TYPE, PerspectiveCameraComponent,
} from '@own/engine';

const vrmGirl = '/mirafinal.vrm';
const rpmAvatar = '/Guy.glb';
const jumpAnimation = '/JumpingDown.fbx';
const danceAnimation = '/MiraDance.glb';

export class MyScene extends Scene {
  public prepareAssets() {

    this.assetBundle.registerAsset({ path: vrmGirl }, VrmAsset);
    this.assetBundle.registerAsset({ path: jumpAnimation }, FbxAsset);
    this.assetBundle.registerAsset({ path: danceAnimation }, GlbAsset);
    this.assetBundle.registerAsset({ path: rpmAvatar }, GlbAsset);
  }

  public buildScene() {
    const camera = this.entityManager.makeEntity(new Three.PerspectiveCamera());
    this.add(new Three.AmbientLight(0xFFFFFF, 1));

    camera.object.position.z = 5;
    camera.addComponent(PerspectiveCameraComponent);

    this.add(camera.object);

    this.add(this.makeVrmEntity().object);
    this.add(this.makeRpmEntity().object);
  }

  protected debugSkeletons(avatarObject: Three.Object3D, animationObject: Three.Object3D) {
    const avatarClone = avatarObject.getObjectByName('Hips')?.clone(true);
    const animationClone = animationObject.getObjectByName('Hips')?.clone(true);

    if (avatarClone && animationClone) {
      this.add(avatarClone);
      this.add(animationClone);
      const avatarSkeletonHelper = new Three.SkeletonHelper(avatarClone);
      const color = avatarSkeletonHelper.geometry.getAttribute('color');
      for (let i = 0; i < color.array.length; i++) {
        color.array[i] = i % 3 == 0 ? 1 : 0;
      }

      const fixArmBonesNames = [
        'LeftArm', 'RightArm',
        'LeftForeArm', 'RightForeArm',
        'LeftHand', 'RightHand',
      ];

      fixArmBonesNames.forEach((name) => {
        const bone = avatarClone.getObjectByName(name);
        if (bone) {
          bone.rotation.set(-0.05, 0, 0);
        }
      });

      const fixLegBonesNames = [
        'LeftUpLeg', 'RightUpLeg',
      ];

      fixLegBonesNames.forEach((name) => {
        const bone = avatarClone.getObjectByName(name);
        if (bone) {
          bone.rotation.z = Math.PI;
        }
      });

      const group = new Three.Group();
      group.add(avatarSkeletonHelper);
      group.add(new Three.SkeletonHelper(animationClone));
      group.position.x = -1.5;
      this.add(group);
    }
  }

  protected makeRpmEntity(): Entity {
    const rmpAvatarAsset = this.assetBundle.getAsset(rpmAvatar, GlbAsset);
    const jumpAnimationAsset = this.assetBundle.getAsset(jumpAnimation, FbxAsset);
    const danceAnimationAsset = this.assetBundle.getAsset(danceAnimation, GlbAsset);

    const rpmEntity = rmpAvatarAsset.makeEntity();
    rmpAvatarAsset.createNormalizedRig(rpmEntity, {
      avatarType: RPM_AVATAR_TYPE,
      targetPose: T_POSE_TYPE,
    });
    this.debugSkeletons(rpmEntity.object, danceAnimationAsset.rig);

    const animationController = new AnimatorController();
    const animatorComponent = rpmEntity.addComponent(AnimatorComponent);
    animatorComponent.controller = animationController;

    const rootFSM = animationController.rootStateMachine;
    animatorComponent.controller = animationController;

    const jumpAnimationClip = jumpAnimationAsset.animationClips[0];
    const danceAnimationClip = danceAnimationAsset.getAnimations()[0];

    if (!jumpAnimationClip || !danceAnimationClip) throw new Error('No animation clips');

    const entryState = rootFSM.entryState;
    const jumpState = rootFSM.addState('jump');
    const danceState = rootFSM.addState('dance');
    jumpState.setMotion(jumpAnimationClip);
    danceState.setMotion(danceAnimationClip);
    entryState.addTransition(jumpState);
    jumpState.addTransition(danceState, { exitTime: 0.1, duration: 0.1 });

    animatorComponent.clipRetargeter.updateRetargetEntry({
      sourceClip: danceAnimationClip,
      sourceClipRig: danceAnimationAsset.rig,
      sourceClipAvatarType: CUSTOM_AVATAR_TYPE,
    });
    animatorComponent.clipRetargeter.updateRetargetEntry({
      sourceClip: jumpAnimationClip,
      sourceClipRig: jumpAnimationAsset.rig,
      sourceClipAvatarType: MIXAMO_AVATAR_TYPE,
    });
    animatorComponent.clipRetargeter.setTargetAvatarType(RPM_AVATAR_TYPE);

    return rpmEntity;
  }

  protected makeVrmEntity(): Entity {
    const vrmGirlAsset = this.assetBundle.getAsset(vrmGirl, VrmAsset);
    const jumpAnimationAsset = this.assetBundle.getAsset(jumpAnimation, FbxAsset);
    const danceAnimationAsset = this.assetBundle.getAsset(danceAnimation, GlbAsset);


    const vrmGirlEntity = vrmGirlAsset.makeAvatarEntity();
    vrmGirlAsset.createNormalizedRig(vrmGirlEntity);
    vrmGirlEntity.getComponent(VrmComponent).getVrm().humanoid.autoUpdateHumanBones = false;

    vrmGirlEntity.object.position.x = 1.2;
    vrmGirlEntity.object.rotation.y = Math.PI;

    const animationController = new AnimatorController();
    const animatorComponent = vrmGirlEntity.addComponent(AnimatorComponent);
    const rootFSM = animationController.rootStateMachine;
    animatorComponent.controller = animationController;

    const entryState = rootFSM.entryState;
    const jumpState = rootFSM.addState('jump');
    const danceState = rootFSM.addState('dance');

    const jumpAnimationClip = jumpAnimationAsset.animationClips[0];
    const danceAnimationClip = danceAnimationAsset.getAnimations()[0];

    if (!jumpAnimationClip || !danceAnimationClip) throw new Error('No animation clips');

    jumpState.setMotion(jumpAnimationClip);
    danceState.setMotion(danceAnimationClip);
    entryState.addTransition(jumpState);
    jumpState.addTransition(danceState, { exitTime: 0.1, duration: 0.1 });

    animatorComponent.clipRetargeter.updateRetargetEntry({
      sourceClip: danceAnimationClip,
      sourceClipRig: danceAnimationAsset.rig,
      sourceClipAvatarType: CUSTOM_AVATAR_TYPE,
    });

    animatorComponent.clipRetargeter.updateRetargetEntry({
      sourceClip: jumpAnimationClip,
      sourceClipRig: jumpAnimationAsset.rig,
      sourceClipAvatarType: MIXAMO_AVATAR_TYPE,
    });
    animatorComponent.clipRetargeter.setTargetAvatarType(VRM_AVATAR_TYPE);

    vrmGirlEntity.getComponent(VrmComponent).vrm?.expressionManager?.setValue('aa', 1);
    vrmGirlEntity.getComponent(VrmComponent).vrm?.expressionManager?.setValue('blinkLeft', 1);

    return vrmGirlEntity;
  }
}
