import * as ReactFlow from 'reactflow';
import { ConnectionLineComponentProps } from 'reactflow';
import { FC } from 'react';

export const TransitionFlowConnector: FC<ConnectionLineComponentProps> = (props) => {
  const { fromX, fromY, toX, toY, connectionLineStyle } = props;
  const [edgePath] = ReactFlow.getStraightPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
  });

  return (
    <g>
      <path
        style={connectionLineStyle}
        fill="none"
        d={edgePath}
      />
      <circle
        cx={toX}
        cy={toY}
        fill="black"
        r={3}
        stroke="black"
        strokeWidth={1.5}
      />
    </g>
  );
};
