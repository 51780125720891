import * as Three from 'three';
import {
  ScriptComponent,
  ScriptComponentOptions,
  InputActionNumber,
  InputActionType,
  InputActionVector2,
  InputModule,
  KeyboardDeviceLayout, MouseDevice, MouseDeviceLayout, TouchscreenDeviceLayout,
  VirtualJoystickDeviceLayout,
} from '@own/engine';

export type CharacterActionsScriptActionsMap = {
  left: InputActionNumber;
  right: InputActionNumber;
  up: InputActionNumber;
  down: InputActionNumber;
  moveDelta: InputActionVector2;
  rotationButton: InputActionNumber;
  p3CameraDelta: InputActionVector2;
  sprintButton: InputActionNumber;
  toggleCameraMode: InputActionNumber;
  aimButton: InputActionNumber;
}

export class CharacterActionsScript extends ScriptComponent {
  public readonly actionsMap: CharacterActionsScriptActionsMap;

  constructor(options: ScriptComponentOptions) {
    super(options);

    this.actionsMap = this.makeActionsMap();
  }

  public get pointerLockIsActive(): boolean {
    return this.getModule(InputModule).inputDeviceManager.getDevice(MouseDevice).pointerLockState === 'locked';
  }

  public getMoveDirection(): Three.Vector2 {
    return new Three.Vector2(
      this.actionsMap.right.readValue() - this.actionsMap.left.readValue(),
      this.actionsMap.down.readValue() - this.actionsMap.up.readValue(),
    );
  }

  protected makeActionsMap(): CharacterActionsScriptActionsMap {
    const actionsMap: CharacterActionsScriptActionsMap = {
      left: this.inputActionManager.addInputAction(InputActionNumber),
      right: this.inputActionManager.addInputAction(InputActionNumber),
      up: this.inputActionManager.addInputAction(InputActionNumber),
      down: this.inputActionManager.addInputAction(InputActionNumber),
      moveDelta: this.inputActionManager.addInputAction(InputActionVector2),
      rotationButton: this.inputActionManager.addInputAction(InputActionNumber),
      p3CameraDelta: this.inputActionManager.addInputAction(InputActionVector2),
      aimButton: this.inputActionManager.addInputAction(InputActionNumber),
      sprintButton: this.inputActionManager.addInputAction(InputActionNumber),
      toggleCameraMode: this.inputActionManager.addInputAction(InputActionNumber, { type: InputActionType.Button }),
    };

    const keyboardLayout = this.getModule(InputModule)
      .inputDeviceLayoutManager.getLayout(KeyboardDeviceLayout);
    const virtualJoystickLayout = this.getModule(InputModule)
      .inputDeviceLayoutManager.getLayout(VirtualJoystickDeviceLayout);

    actionsMap.left.addBinding(keyboardLayout.KeyA);
    actionsMap.left.addBinding(virtualJoystickLayout.left);
    actionsMap.right.addBinding(keyboardLayout.KeyD);
    actionsMap.right.addBinding(virtualJoystickLayout.right);
    actionsMap.up.addBinding(keyboardLayout.KeyW);
    actionsMap.up.addBinding(virtualJoystickLayout.up);
    actionsMap.down.addBinding(keyboardLayout.KeyS);
    actionsMap.down.addBinding(virtualJoystickLayout.down);

    const mouseLayout = this.getModule(InputModule).inputDeviceLayoutManager.getLayout(MouseDeviceLayout);
    const touchScreenLayout = this.getModule(InputModule).inputDeviceLayoutManager.getLayout(TouchscreenDeviceLayout);

    actionsMap.moveDelta.addBinding(mouseLayout.delta);
    actionsMap.moveDelta.addBinding(touchScreenLayout.primaryTouch.delta);
    actionsMap.rotationButton.addBinding(mouseLayout.leftButton);
    actionsMap.rotationButton.addBinding(touchScreenLayout.primaryTouch.press);
    actionsMap.p3CameraDelta.addBinding(mouseLayout.scroll);
    actionsMap.aimButton.addBinding(mouseLayout.rightButton);
    actionsMap.sprintButton.addBinding(keyboardLayout.ShiftLeft);
    actionsMap.toggleCameraMode.addBinding(keyboardLayout.KeyC);

    return actionsMap;
  }
}
