import * as Three from 'three';
import {
  GlbAsset,
  HdrAsset,
  AnimatorComponent,
  EnvironmentComponent,
  AnimatorController,
  Scene,
  Entity,
  PerspectiveCameraComponent,
} from '@own/engine';

const helmUrl = 'https://threejs.org/examples/models/gltf/Xbot.glb';
const envUrl = 'https://threejs.org/examples/textures/equirectangular/royal_esplanade_1k.hdr';

export class MyScene extends Scene {
  public prepareAssets() {

    this.assetBundle.registerAsset({ path: helmUrl }, GlbAsset);
    this.assetBundle.registerAsset({ path: envUrl }, HdrAsset);
  }

  public buildScene() {
    const humanAsset = this.assetBundle.getAsset(helmUrl, GlbAsset);
    const camera = this.entityManager.makeEntity(new Three.PerspectiveCamera());
    camera.addComponent(PerspectiveCameraComponent);

    const animatorController = new AnimatorController();
    const rootFSM = animatorController.rootStateMachine;

    this.add(this.makeEnvironmentController().object);
    this.add(camera.object);

    camera.object.position.z = 5;

    const humanEntity = humanAsset.makeEntity();

    const animations = humanAsset.getAnimations();

    const entryState = rootFSM.entryState;
    const idleState = rootFSM.addState('idle');
    const runState = rootFSM.addState('run');
    const walkState = rootFSM.addState('walk');

    idleState.setMotion(animations.find((animation) => animation.name === 'idle') as Three.AnimationClip);
    runState.setMotion(animations.find((animation) => animation.name === 'run') as Three.AnimationClip);
    walkState.setMotion(animations.find((animation) => animation.name === 'walk') as Three.AnimationClip);

    idleState.position.set(0, -400);
    runState.position.set(400, -400);
    walkState.position.set(400, 0);

    entryState.addTransition(idleState);
    idleState.addTransition(runState, { exitTime: 0.8, duration: 2 });
    idleState.addTransition(walkState, { exitTime: 0.8, duration: 2 });
    walkState.addTransition(idleState, { exitTime: 0.8, duration: 2 });
    runState.addTransition(idleState);

    animatorController.parameters.declareBooleanParameter('boolParam');
    animatorController.parameters.declareNumberParameter('numberParam');

    humanEntity.addComponent(AnimatorComponent).controller = animatorController;

    this.add(humanEntity.object);
  }

  protected makeEnvironmentController(): Entity {
    const envAsset = this.assetBundle.getAsset(envUrl, HdrAsset);
    const envController = this.entityManager.makeEntity();
    const envComponent = envController.addComponent(EnvironmentComponent);

    envComponent.environmentTexture = envAsset.texture;
    envComponent.backgroundTexture = envAsset.texture;

    return envController;
  }
}
