import { Edge, MarkerType, Node, Position } from 'reactflow';
import { AnimatorController } from '@own/engine';

export const makeFlowFromAnimatorController = (animatorController: AnimatorController) => {
  const nodes: Node[] = animatorController.rootStateMachine.states.map((state) => {
    return {
      id: state.name,
      type: 'custom',
      data: {
        label: state.name,
        isActive: animatorController.rootStateMachine.activeState === state,
        animationProgress: state.motion?.normalizedTime ?? 0,
      },
      position: { ...state.position },
      sourcePosition: Position.Left,
      targetPosition: Position.Left,
    };
  });

  const edges: Edge[] = animatorController.rootStateMachine.states.reduce<Edge[]>((acc, state) => {
    return [...acc, ...state.transitions.map((transition): Edge => {
      return {
        id: transition.name,
        source: state.name,
        target: transition.destinationState.name,
        data: {
          isActive: transition === state.activeTransition,
          transitionProgress: transition.normalizedTime,
        },
        style: { strokeWidth: 3, stroke: 'black' },
        type: 'floating',
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: 'black',
        },
      };
    })];
  }, []);

  return {
    nodes,
    edges,
  };
};
