import * as Three from 'three';
import {
  ScriptComponent,
  ScriptComponentProps,
  BoxColliderComponent,
  RigidBodyComponent,
  RigidBodyType,
  RenderModule,
  InputActionNumber,
  InputModule,
  KeyboardDeviceLayout,
  ComponentOptions,
  Entity,
} from '@own/engine';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { OrbitControllerScript } from '../../../packages';

export class Manipulator extends ScriptComponent {
  public camera?: Entity;

  public orbitController: Entity | null = null;

  protected _isStarted = false;

  protected moveVector = new Three.Vector2();

  protected rootObject!: Entity;

  protected actions: {
    left: InputActionNumber;
    right: InputActionNumber;
    up: InputActionNumber;
    down: InputActionNumber;
  };

  public fixedUpdate(): void {
    if (!this._isStarted) {
      this._isStarted = true;
      this.makeObject();
    }

    this.moveVector.set(
      this.actions.right.readValue() - this.actions.left.readValue(),
      this.actions.up.readValue() - this.actions.down.readValue(),
    ).normalize();

    if (this.moveVector.length()) {
      const delta = new Three.Vector3(this.moveVector.x, 0, this.moveVector.y).multiplyScalar(0.1);
      this.rootObject.getComponent(RigidBodyComponent).position
        .add(delta);
    }
  }

  constructor(options: ComponentOptions<ScriptComponentProps>) {
    super(options);

    const actionManager = this._ctx.getModule(InputModule).inputActionManager;

    this.actions = {
      left: actionManager.addInputAction(InputActionNumber),
      right: actionManager.addInputAction(InputActionNumber),
      up: actionManager.addInputAction(InputActionNumber),
      down: actionManager.addInputAction(InputActionNumber),
    };

    const keyboardDeviceLayout = this._ctx.getModule(InputModule).inputDeviceLayoutManager.getLayout(KeyboardDeviceLayout);

    this.actions.left.addBinding(keyboardDeviceLayout.ArrowLeft);
    this.actions.right.addBinding(keyboardDeviceLayout.ArrowRight);
    this.actions.up.addBinding(keyboardDeviceLayout.ArrowUp);
    this.actions.down.addBinding(keyboardDeviceLayout.ArrowDown);
  }

  update() {
    super.update();
  }

  protected makeObject(): void {
    const rootEntity = this.entityManager.makeEntity(new Three.Object3D());
    const boxMesh = new Three.Mesh(
      new Three.BoxGeometry(1, 1, 1),
      new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#55c4ff') }),
    );
    const boxEntity = this.entityManager.makeEntity(boxMesh);

    this.rootObject = rootEntity;
    boxMesh.geometry.translate(0, 0, 0);

    rootEntity.object.add(boxEntity.object);

    const rigidBody = rootEntity.addComponent(RigidBodyComponent, {
      type: RigidBodyType.Dynamic,
      mass: 20,
    });
    rootEntity.addComponent(BoxColliderComponent, {
      size: new Three.Vector3(1, 1, 1),
    });

    rootEntity.object.position.set(0, 1, 0);

    if (!this.camera) throw new Error('Camera not found');

    const controls = new TransformControls(
      this.camera.getObjectAs(Three.PerspectiveCamera),
      this._ctx.getModule(RenderModule).renderer.webglRenderer.domElement,
    );

    controls.attach(rootEntity.object);

    controls.addEventListener('mouseDown', () => {
      this.getOrbitControllerComponent().inputsAreActive = false;
      rigidBody.type = RigidBodyType.Kinematic;
      rigidBody.needsUpdate = true;
    });

    controls.addEventListener('mouseUp', () => {
      this.getOrbitControllerComponent().inputsAreActive = true;
      rigidBody.type = RigidBodyType.Dynamic;
      rigidBody.needsUpdate = true;
    });

    controls.addEventListener('objectChange', () => {
      const rb = rootEntity.getComponent(RigidBodyComponent);
      const collider = rootEntity.getComponent(BoxColliderComponent);

      rb.position.copy(rootEntity.object.position);
      rb.position.y = Math.max(rb.position.y, 0);
      rb.position.add(collider.center);
    });

    this.entity.object.add(controls);
    this.entity.object.add(rootEntity.object);
  }

  protected getOrbitControllerComponent(): OrbitControllerScript {
    if (!this.orbitController) throw new Error('Orbit controller not found');

    return this.orbitController.getComponent(OrbitControllerScript);
  }
}
