import * as Three from 'three';
import { Pane } from 'tweakpane';
import {
  Scene,
  FbxAsset,
  ImageAsset,
  RenderModule,
  PhysicsModule,
  CameramanBrainComponent,
  CameramanVCameraCollider,
  InputModule,
  MouseDevice,
  VrmAsset,
  POVLookAt,
  FramingTransposerFollow, Entity, PerspectiveCameraComponent,
} from '@own/engine';
import * as assetsUrls from './assetsUrls';
import { buildCubes, buildFloor, buildLights, buildWalls, buildMixamoCharacter } from './builders';
import { CharacterControllerScript, CharacterLogicStateControllerScript, IdleState } from '../../packages/character-controller';

export class MyScene extends Scene {
  public pane: Pane = new Pane();

  public prepareAssets() {
    this.assetBundle.registerAsset({ path: assetsUrls.vrmModel }, VrmAsset);
    this.assetBundle.registerAsset({ path: assetsUrls.mixamoModel }, FbxAsset);
    this.assetBundle.registerAsset({ path: assetsUrls.mixamoIdleAnimation }, FbxAsset);
    this.assetBundle.registerAsset({ path: assetsUrls.mixamoWalkAnimation }, FbxAsset);
    this.assetBundle.registerAsset({ path: assetsUrls.mixamoRunAnimation }, FbxAsset);
    this.assetBundle.registerAsset({ path: assetsUrls.prototypeTileImage }, ImageAsset);
  }

  public buildScene() {
    const camera = this.entityManager.makeEntity(new Three.PerspectiveCamera());
    camera.addComponent(PerspectiveCameraComponent);
    camera.addComponent(CameramanBrainComponent);

    const firstCharacter = buildMixamoCharacter({
      scene: this,
      characterEntityName: 'first-character',
      active: true,
      p3CameraColliderIsActive: false,
      debugP3VCameraCollider: false,
      debugP3VCamera: false,
    });
    const secondCharacter = buildMixamoCharacter({
      scene: this,
      characterEntityName: 'second-character',
      active: false,
      p3CameraColliderIsActive: true,
      debugP3VCameraCollider: true,
      debugP3VCamera: true,
    });

    firstCharacter.object.position.set(0, 0, -5);
    secondCharacter.object.position.set(-2, 0, -5);

    this.add(secondCharacter.object);
    this.add(firstCharacter.object);

    this.add(camera.object);
    this.add(buildFloor(this).object);
    this.add(...buildCubes(this).map(cube => cube.object));
    this.add(...buildLights());

    this.setupShadows();
    this.setupRenderer();
    this.add(...buildWalls(this).map(wall => wall.object));

    this.setupPane(firstCharacter, secondCharacter);
  }

  public destroy(): void {
    super.destroy();
    this.pane?.dispose();
  }

  public setupRenderer() {
    this._ctx.getModule(RenderModule).renderer.webglRenderer.toneMapping = Three.ReinhardToneMapping;
    this._ctx.getModule(RenderModule).renderer.webglRenderer.toneMappingExposure = 2.3;
    this._ctx.getModule(RenderModule).renderer.webglRenderer.shadowMap.enabled = true;
  }

  public setupShadows() {
    this.traverse(object => {
      if (!(object instanceof Three.Mesh)) return;

      object.castShadow = true;
      object.receiveShadow = true;
      if (object.material.map) object.material.map.anisotropy = 16;
    });
  }

  protected setupPane(firstCharacter: Entity, secondCharacter: Entity) {
    this.pane.addButton({ title: 'Pointer lock' }).on('click', () => {
      this._ctx.getModule(InputModule).inputDeviceManager.getDevice(MouseDevice).requestPointerLock();
    });

    this.pane.addButton({ title: 'Toggle character' }).on('click', () => {
      const firstCharacterController = firstCharacter.getComponent(CharacterControllerScript);
      const secondCharacterController = secondCharacter.getComponent(CharacterControllerScript);

      firstCharacterController.controllerIsActive = !firstCharacterController.controllerIsActive;
      secondCharacterController.controllerIsActive = !secondCharacterController.controllerIsActive;

      firstCharacter.getComponent(CharacterLogicStateControllerScript).forceCurrentState(IdleState);
      secondCharacter.getComponent(CharacterLogicStateControllerScript).forceCurrentState(IdleState);
    });

    this.pane.addButton({ title: 'Toggle debugger' }).on('click', () => {
      this.toggleDebuggerObject();
    });

    const secondCharacterP3VCameraComponent = secondCharacter.getComponent(CharacterControllerScript).getP3VCameraComponent();
    const secondCharacterP3VCameraColliderComponent = secondCharacterP3VCameraComponent.entity.getComponent(CameramanVCameraCollider);

    const povLookAt = secondCharacterP3VCameraComponent.getLookAtAs(POVLookAt);
    const transposerFollow = secondCharacterP3VCameraComponent.getFollowAs(FramingTransposerFollow);


    povLookAt.horizontalAngle = 0;
    povLookAt.verticalAngle = -0.55;
    transposerFollow.distance = 3.30;

    this.pane.addBinding(povLookAt, 'horizontalAngle', {
      label: 'Horizontal angle',
      min: 0,
      max: Math.PI * 2,
      step: 0.01,
    });

    this.pane.addBinding(povLookAt, 'verticalAngle', {
      label: 'Vertical angle',
      min: -Math.PI,
      max: Math.PI,
      step: 0.01,
    });

    this.pane.addBinding(transposerFollow, 'distance', {
      label: 'Distance',
      min: 1,
      max: 10,
      step: 0.01,
    });


    this.pane.addBinding(secondCharacterP3VCameraColliderComponent, 'debug', { label: 'Debug Vcam Collider' });
    this.pane.addBinding(secondCharacterP3VCameraComponent, 'debug', { label: 'Debug Vcam' });

    const wall1 = this.getObjectByName('wall1') as Three.Object3D;
    const wall2 = this.getObjectByName('wall2') as Three.Object3D;

    const wall1Entity = this.entityManager.getEntity(wall1);
    const wall2Entity = this.entityManager.getEntity(wall2);

    this.pane.addBinding(wall1Entity, 'isActive', { label: 'Wall 1 active' });
    this.pane.addBinding(wall2Entity, 'isActive', { label: 'Wall 2 active' });
  }

  protected toggleDebuggerObject() {
    const debuggerObject = this._ctx.getModule(PhysicsModule).getEngine().getDebuggerObject();

    if (debuggerObject.parent === null) {
      this.add(debuggerObject);
    } else {
      this.remove(debuggerObject);
    }
  }
}
