import * as Three from 'three';
import { ScriptComponent, ScriptComponentProps, ComponentOptions } from '@own/engine';

export type MoveDummyComponentProps = ScriptComponentProps & {
  xOffset: number;
};

export class MoveDummyScript extends ScriptComponent<MoveDummyComponentProps> {
  protected _time = 0;

  protected _xOffset: number;

  constructor(options: ComponentOptions<MoveDummyComponentProps>) {
    super(options);
    this._xOffset = options.props?.xOffset ?? 0;
    this._time = options.props?.xOffset ?? 0;
  }

  public update() {
    this._time += this.time.timeDelta;

    const spherical = new Three.Spherical(2, this._time, this._time + Math.PI / 2);

    this.entity.object.position
      .setFromSpherical(spherical).add(new Three.Vector3(0, 2, 0))
      .add(new Three.Vector3(this._xOffset, 0, 0));
  }
}
