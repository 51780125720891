import * as Three from 'three';
import {
  BoxColliderComponent,
  RigidBodyActivationState,
  RigidBodyComponent,
  RigidBodyType,
  Entity,
} from '@own/engine';
import { MyScene } from '../MyScene';


export const buildWalls = (scene: MyScene): Entity[] => {
  // wall 1
  const box = scene.entityManager.makeEntity(new Three.Mesh());
  const geometry = new Three.BoxGeometry(20, 5, 1);
  const material = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#fff7b5') });
  box.object.add(new Three.Mesh(geometry, material));
  box.addComponent(BoxColliderComponent, {
    size: new Three.Vector3(20, 5, 1),
  });
  box.addComponent(RigidBodyComponent, {
    type: RigidBodyType.Static,
    activationState: RigidBodyActivationState.CanSleep,
    mass: 0,
  });

  box.object.position.set(-10, 0, 0);

  // parallel wall1
  const box2 = scene.entityManager.makeEntity(new Three.Mesh());
  const geometry2 = new Three.BoxGeometry(20, 5, 1);
  const material2 = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#fff7b5') });
  box2.object.add(new Three.Mesh(geometry2, material2));
  box2.addComponent(BoxColliderComponent, {
    size: new Three.Vector3(20, 5, 1),
  });
  box2.addComponent(RigidBodyComponent, {
    type: RigidBodyType.Static,
    activationState: RigidBodyActivationState.CanSleep,
    mass: 0,
  });
  box2.object.position.set(-10, 0, -3);

  // ceiling
  const ceiling = scene.entityManager.makeEntity();
  const ceilingGeometry = new Three.BoxGeometry(5, 0.2, 5);
  const ceilingMaterial = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#fff7b5') });
  ceiling.object.add(new Three.Mesh(ceilingGeometry, ceilingMaterial));
  ceiling.addComponent(BoxColliderComponent, {
    size: new Three.Vector3(5, 0.2, 5),
  });
  ceiling.addComponent(RigidBodyComponent, {
    type: RigidBodyType.Static,
    activationState: RigidBodyActivationState.CanSleep,
    mass: 0,
  });
  ceiling.object.position.set(0, 4, 0);

  box.object.name = 'wall1';
  box2.object.name = 'wall2';

  return [box, box2, ceiling];
};
