import * as Three from 'three';
import { ScriptComponent, ScriptComponentProps } from '@own/engine';
import { ComponentOptions, Entity } from '@own/engine';
import { CameramanVCameraComponent } from '@own/engine';
import {
  OrbitalLockToTargetFollow,
} from '@own/engine';
import { SpaceMode } from '@own/engine';
import { LockToTargetLookAt } from '@own/engine';
import { InputActionNumber, InputActionVector2, InputModule, MouseDeviceLayout } from '@own/engine';
import { RenderModule } from '@own/engine';

export type OrbitControllerScriptProps = ScriptComponentProps & {
  target?: Entity;
  initialRadius?: number;
  initialPhi?: number;
  initialTheta?: number;
  minRadius?: number;
  maxRadius?: number;
  minPhi?: number;
  maxPhi?: number;
  minTheta?: number;
  maxTheta?: number;
  followDamping?: number;
  rotationDamping?: number;
  rotationSpeed?: number;
  radiusSpeed?: number;
  isActive?: boolean;
  inputsAreActive?: boolean;
  followOffset?: Three.Vector3;
  lookAtOffset?: Three.Vector3;
  fov?: number;
  near?: number;
  far?: number;
  debugVCamera?: boolean;
}

type InputActions = {
  moveDelta: InputActionVector2;
  rotationButton: InputActionNumber;
  panButton: InputActionNumber;
  radiusDelta: InputActionVector2;
}

export class OrbitControllerScript extends ScriptComponent<OrbitControllerScriptProps> {
  public initialRadius: number = 5;

  public initialPhi: number = Math.PI / 2;

  public initialTheta: number = 0;

  public minRadius: number = 3;

  public maxRadius: number = 15;

  public minPhi: number = 45 * (Math.PI / 180);

  public maxPhi: number = 90 * (Math.PI / 180);

  public minTheta: number = -Infinity;

  public maxTheta: number = Infinity;

  public rotationSpeed: number = Math.PI * 2;

  public radiusSpeed: number = 1;

  public inputsAreActive: boolean = true;

  protected _vCameraComponent: CameramanVCameraComponent;

  protected _inputActions: InputActions;

  public get isActive(): boolean {
    return this._vCameraComponent.isActive;
  }

  public set isActive(value: boolean) {
    this._vCameraComponent.isActive = value;
  }

  public get followOffset(): Three.Vector3 {
    return this._vCameraComponent.getFollowAs(OrbitalLockToTargetFollow).followOffset;
  }

  public get lookAtOffset(): Three.Vector3 {
    return this._vCameraComponent.getLookAtAs(LockToTargetLookAt).lookAtOffset;
  }

  public get followDamping(): number {
    return this._vCameraComponent.getFollowAs(OrbitalLockToTargetFollow).followDamping;
  }

  public set followDamping(value: number) {
    this._vCameraComponent.getFollowAs(OrbitalLockToTargetFollow).followDamping = value;
  }

  public get rotationDamping(): number {
    return this._vCameraComponent.getFollowAs(OrbitalLockToTargetFollow).rotationDamping;
  }

  public set rotationDamping(value: number) {
    this._vCameraComponent.getFollowAs(OrbitalLockToTargetFollow).rotationDamping = value;
  }

  public get target(): Entity | undefined {
    return this._vCameraComponent.followTarget;
  }

  public set target(value: Entity | undefined) {
    this._vCameraComponent.followTarget = value;
    this._vCameraComponent.lookAtTarget = value;
  }

  protected get rendererDomElement(): HTMLCanvasElement {
    return this._ctx.getModule(RenderModule).renderer.domElement;
  }

  constructor(options: ComponentOptions<OrbitControllerScriptProps>) {
    super(options);
    this.initialRadius = options.props?.initialRadius ?? this.initialRadius;
    this.initialPhi = options.props?.initialPhi ?? this.initialPhi;
    this.initialTheta = options.props?.initialTheta ?? this.initialTheta;
    this.minRadius = options.props?.minRadius ?? this.minRadius;
    this.maxRadius = options.props?.maxRadius ?? this.maxRadius;
    this.radiusSpeed = options.props?.radiusSpeed ?? this.radiusSpeed;
    this.minPhi = options.props?.minPhi ?? this.minPhi;
    this.maxPhi = options.props?.maxPhi ?? this.maxPhi;
    this.minTheta = options.props?.minTheta ?? this.minTheta;
    this.maxTheta = options.props?.maxTheta ?? this.maxTheta;
    this.rotationSpeed = options.props?.rotationSpeed ?? this.rotationSpeed;
    this.inputsAreActive = options.props?.inputsAreActive ?? this.inputsAreActive;
    this._vCameraComponent = this.addVCameraComponent(options?.props);
    this._inputActions = this.addInputActions();
  }

  public update(): void {
    if (!this.inputsAreActive) return;
    if (this._inputActions.rotationButton.isPerformed()) this.updateRotation();
    if (this._inputActions.panButton.isPerformed()) this.updatePan();
    if (this._inputActions.radiusDelta.readValue().y !== 0) this.updateRadius();
  }

  protected updateRotation(): void {
    const follow = this._vCameraComponent.getFollowAs(OrbitalLockToTargetFollow);
    const rotationDelta = this._inputActions.moveDelta.readValue();

    // todo: need processors for input actions
    const normalizedRotationDelta = rotationDelta
      .clone()
      .divide(new Three.Vector2(this.rendererDomElement.width, this.rendererDomElement.height));


    follow.phi += normalizedRotationDelta.y * this.rotationSpeed;
    follow.theta -= normalizedRotationDelta.x * this.rotationSpeed;

    follow.phi = Three.MathUtils.clamp(follow.phi, this.minPhi, this.maxPhi);
    follow.theta = Three.MathUtils.clamp(follow.theta, this.minTheta, this.maxTheta);
  }

  protected updateRadius(): void {
    const follow = this._vCameraComponent.getFollowAs(OrbitalLockToTargetFollow);
    const deltaSign = Math.sign(this._inputActions.radiusDelta.readValue().y);

    follow.radius += deltaSign * this.radiusSpeed;
    follow.radius = Three.MathUtils.clamp(follow.radius, this.minRadius, this.maxRadius);
  }

  protected updatePan(): void {
    const panDelta = this._inputActions.moveDelta.readValue();
    const rendererDomElement = this._ctx.getModule(RenderModule).renderer.domElement;
    const targetPosition = this._vCameraComponent.followTarget?.object.position ?? new Three.Vector3(0, 0, 0);

    let targetDistance = new Three.Vector3().copy(this.entity.object.position).sub(targetPosition).length();

    targetDistance *= Math.tan((this._vCameraComponent.fov / 2) * Math.PI / 180.0);

    const verticalOffset = new Three.Vector3();
    verticalOffset.setFromMatrixColumn(this.entity.object.matrix, 1);
    verticalOffset.multiplyScalar(2 * -panDelta.y * targetDistance / rendererDomElement.clientHeight);

    const horizontalOffset = new Three.Vector3();
    horizontalOffset.setFromMatrixColumn(this.entity.object.matrix, 0);
    horizontalOffset.multiplyScalar(2 * -panDelta.x * targetDistance / rendererDomElement.clientHeight);


    this.followOffset.add(verticalOffset).add(horizontalOffset);
    this.lookAtOffset.add(verticalOffset).add(horizontalOffset);
  }

  protected addVCameraComponent(props: OrbitControllerScriptProps = {}): CameramanVCameraComponent {
    return this.entity.addComponent(CameramanVCameraComponent, {
      debug: props.debugVCamera ?? false,
      isActive: props.isActive,
      followTarget: props.target,
      follow: new OrbitalLockToTargetFollow({
        spaceMode: SpaceMode.World,
        followOffset: props.followOffset ?? new Three.Vector3(0, 0, 0),
        followDamping: props.followDamping ?? 0,
        rotationDamping: props.rotationDamping ?? 0.8,
        phi: this.initialPhi,
        theta: this.initialTheta,
        radius: this.initialRadius,
      }),
      lookAtTarget: props.target,
      lookAt: new LockToTargetLookAt({
        spaceMode: SpaceMode.World,
        lookAtOffset: props.lookAtOffset ?? new Three.Vector3(0, 0, 0),
      }),
      fov: props.fov ?? 45,
      near: props.near ?? 0.1,
      far: props.far ?? 1000,
    });
  }

  protected addInputActions(): InputActions {
    const actions = {
      moveDelta: this.inputActionManager.addInputAction(InputActionVector2),
      rotationButton: this.inputActionManager.addInputAction(InputActionNumber),
      radiusDelta: this.inputActionManager.addInputAction(InputActionVector2),
      panButton: this.inputActionManager.addInputAction(InputActionNumber),
    };

    const mouseLayout = this.getModule(InputModule).inputDeviceLayoutManager.getLayout(MouseDeviceLayout);

    actions.moveDelta.addBinding(mouseLayout.delta);
    actions.rotationButton.addBinding(mouseLayout.leftButton);
    actions.radiusDelta.addBinding(mouseLayout.scroll);
    actions.panButton.addBinding(mouseLayout.rightButton);

    return actions;
  }
}
