import * as Three from 'three';
import { BoxColliderComponent, Entity, RigidBodyComponent, RigidBodyType } from '@own/engine';
import { MyScene } from '../MyScene';

export const buildWalls = (scene: MyScene): Entity[] => {
  const wall1 = makeWall(1, 5, 1, new Three.Vector3(2, 0, 0), scene);
  const wall2 = makeWall(1, 5, 1, new Three.Vector3(-2, 0, 0), scene);
  const wall3 = makeWall(1, 5, 5, new Three.Vector3(0, 0, -5), scene);
  wall3.object.rotation.y = Math.PI * 0.95;

  return [wall1, wall2, wall3];
};

const makeWall = (width: number, height: number, depth: number, position: Three.Vector3, scene: MyScene): Entity => {
  const wall = scene.entityManager.makeEntity(new Three.Mesh());
  const wallMesh = wall.getObjectAs(Three.Mesh);

  wallMesh.geometry = new Three.BoxGeometry(width, height, depth);
  wallMesh.material = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#2a55ff') });

  wallMesh.position.copy(position);
  wall.addComponent(RigidBodyComponent, {
    type: RigidBodyType.Static,
  });
  wall.addComponent(BoxColliderComponent, {
    size: new Three.Vector3(width, height, depth),
  });

  return wall;
};
