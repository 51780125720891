import { FC, useCallback, useEffect, useState } from 'react';
import { Bootloader, RenderModule, ApplicationModule } from '@own/engine';
import { MyScene } from './MyScene';
import { Box, Fade, Flex, Spinner, useToast } from '@chakra-ui/react';

export const InputsExample: FC = () => {
  const toast = useToast()
  const [app, setApp] = useState<ApplicationModule>();
  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);

  useEffect(() => {
    let isInitialized = false;
    let isDestroyed = false;
    let app: ApplicationModule | undefined;

    const makeApp = async () => {
      app = await new Bootloader().makeApp();

      setApp(app);
      await app.runScene(MyScene);
      setSceneIsLoaded(true);

      isInitialized = true;
      if (isDestroyed) app.destroyContext();
    };

    makeApp().catch((e) => {
      toast({
        title: 'Failed to initialize the app',
        description: e.message,
        status: 'error',
      })
      console.error(e);
    });

    return () => {
      isDestroyed = true;
      if (isInitialized && app) app.destroyContext();
      setApp(undefined);
      setSceneIsLoaded(false);
    };
  }, []);

  const engineCanvasRef = useCallback((node: HTMLDivElement) => {
    if (!node || !app) return;

    node.appendChild(app.context.getModule(RenderModule).renderer.webglRenderer.domElement);
  }, [app]);

  const isReady = sceneIsLoaded && !!app;

  return (
    <Box height="100vh">
      <Flex
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner size="xl" />
      </Flex>
      <Fade
        in={isReady}
        style={{ height: '100%', position: 'relative' }}
      >
        {isReady && <Box
          height="100%"
          width="100%"
          flexShrink={0}
          flexGrow={0}
          ref={engineCanvasRef}
        />}
      </Fade>
    </Box>);
};
