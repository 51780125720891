import { FC, useCallback } from 'react';
import {
  Divider,
  HStack, IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react';
import { FaTrashCan } from 'react-icons/fa6';

export type PanelInputNumberFieldProps = {
  name: string;
  defaultValue?: number;
  onChange?: (value: number) => void;
  isDisabled?: boolean;
  removable?: boolean;
  onRemove?: () => void;
}

export const PanelInputNumberField: FC<PanelInputNumberFieldProps> = (props) => {
  const handleChange = useCallback((value: string) => {
    if (!props.onChange) return;

    props.onChange(parseFloat(value));
  }, [props]);

  return <>
    <HStack>
      <Text
        fontSize="xs"
        flexGrow={1}
        flexShrink={1}
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {props.name}
      </Text>
      <NumberInput
        width="100px"
        flexGrow={0}
        flexShrink={0}
        min={0}
        max={5}
        step={0.1}
        size="xs"
        isDisabled={props.isDisabled}
        defaultValue={props.defaultValue}
        onChange={handleChange}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {props.removable && <IconButton
        flexShrink={0}
        aria-label="delete parameter"
        colorScheme="red"
        icon={<FaTrashCan />}
        size="xs"
        onClick={props.onRemove}
      />}
    </HStack>
    <Divider margin="10px 0" />
  </>;
};
