import * as Three from 'three';
import {
  ScriptComponent,
  ScriptComponentProps,
  ComponentOptions,
  InputActionNumber,
  InputActionType,
  InputModule,
  KeyboardDeviceLayout,
  RigidBodyComponent,
} from '@own/engine';

export type ControlledDummyComponentProps = ScriptComponentProps & {
  speed?: number;
};

export class ControlledDummyScript extends ScriptComponent<ControlledDummyComponentProps> {
  declare protected _actions: {
    left: InputActionNumber;
    right: InputActionNumber;
    up: InputActionNumber;
    down: InputActionNumber;
  };

  protected _moveVector = new Three.Vector2();

  protected _speed: number;

  constructor(options: ComponentOptions<ControlledDummyComponentProps>) {
    super(options);
    this._speed = options.props?.speed ?? 1;

    this.initActions();
  }

  public fixedUpdate() {
    super.fixedUpdate();


    const rotateAngleY = -this._moveVector.x * this.time.fixedTimeDelta * (Math.PI / 2);
    this.entity.getComponent(RigidBodyComponent).rotateOnAxis(new Three.Vector3(0, 1, 0), rotateAngleY);
    this.entity.getComponent(RigidBodyComponent).position.add(new Three.Vector3(
      0,
      0,
      this._moveVector.y * this.time.fixedTimeDelta * this._speed,
    ).applyQuaternion(this.entity.getComponent(RigidBodyComponent).quaternion));
  }

  public update() {
    this._moveVector.set(
      this._actions.right.readValue() - this._actions.left.readValue(),
      this._actions.down.readValue() - this._actions.up.readValue(),
    );
  }

  public initActions() {
    this._actions = {
      left: this.getModule(InputModule).inputActionManager.addInputAction(InputActionNumber, {
        name: 'left',
        type: InputActionType.Value,
      }),
      right: this.getModule(InputModule).inputActionManager.addInputAction(InputActionNumber, {
        name: 'right',
        type: InputActionType.Value,
      }),
      up: this.getModule(InputModule).inputActionManager.addInputAction(InputActionNumber, {
        name: 'up',
        type: InputActionType.Value,
      }),
      down: this.getModule(InputModule).inputActionManager.addInputAction(InputActionNumber, {
        name: 'down',
        type: InputActionType.Value,
      }),
    };

    const keyboardLayout = this.getModule(InputModule)
      .inputDeviceLayoutManager.getLayout(KeyboardDeviceLayout);

    this._actions.left.addBinding(keyboardLayout.ArrowLeft);
    this._actions.right.addBinding(keyboardLayout.ArrowRight);
    this._actions.up.addBinding(keyboardLayout.ArrowUp);
    this._actions.down.addBinding(keyboardLayout.ArrowDown);
  }
}
