import { FC, MouseEventHandler, useCallback, useRef } from 'react';
import * as ReactFlow from 'reactflow';
import { Background, BackgroundVariant, EdgeMouseHandler, useEdgesState, useNodesState } from 'reactflow';
import 'reactflow/dist/style.css';
import { TransitionFlowConnector } from '../TransitionFlowConnector';
import { useEngine, useRequestAnimationFrame } from '../../hooks';
import { makeFlowFromAnimatorController } from '../../utils';
import { useEditorStore } from '../../store';
import { reactFlowConfig } from './reactFlowConfig';

export const FlowCanvas: FC = () => {
  const paneRef = useRef<HTMLDivElement>(null);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const { getAnyAnimationController } = useEngine();
  const {
    openPaneContextMenu,
    openStateContextMenu,
    openTransitionContextMenu,
    closeContextMenu,
    openStateEditPanel,
    openTransitionsEditPanel,
    openParameterEditPanel,
    closeEditPanel,
  } = useEditorStore();

  const handleNodeContextMenuTrigger: ReactFlow.NodeMouseHandler = useCallback((event, node) => {
    event.preventDefault();

    const paneRect = paneRef.current?.getBoundingClientRect();

    if (!paneRect) return;

    closeContextMenu();
    openStateContextMenu({
      stateName: node.id,
      position: {
        x: event.clientX - paneRect.left,
        y: event.clientY - paneRect.top,
      },
    });
  }, [closeContextMenu, openStateContextMenu]);

  const handleConnect: ReactFlow.OnConnect = useCallback((params) => {
    const sourceState = getAnyAnimationController().rootStateMachine.states.find((state) => state.name === params.source);
    const targetState = getAnyAnimationController().rootStateMachine.states.find((state) => state.name === params.target);

    if (!sourceState || !targetState) throw new Error('Invalid source or target state');

    sourceState.addTransition(targetState);
  }, [getAnyAnimationController]);

  const handleNodeChange: ReactFlow.OnNodesChange = useCallback((nodes) => {
    nodes.forEach((node) => {
      if (node.type === 'position' && node.position) {
        const state = getAnyAnimationController().rootStateMachine.states.find((state) => state.name === node.id);
        if (state) state.position.set(node.position.x, node.position.y);
      }
    });
  }, [getAnyAnimationController]);

  const handlePaneContextMenuTrigger: MouseEventHandler = useCallback((event) => {
    event.preventDefault();

    const paneRect = paneRef.current?.getBoundingClientRect();

    if (!paneRect) return;

    closeContextMenu();
    openPaneContextMenu({
      position: {
        x: event.clientX - paneRect.left,
        y: event.clientY - paneRect.top,
      },
    });
  }, [closeContextMenu, openPaneContextMenu]);

  const handleEdgeContextMenuTrigger: EdgeMouseHandler = useCallback((event, edge) => {
    event.preventDefault();

    const paneRect = paneRef.current?.getBoundingClientRect();

    if (!paneRect) return;

    closeContextMenu();
    openTransitionContextMenu({
      transitionName: edge.id,
      position: {
        x: event.clientX - paneRect.left,
        y: event.clientY - paneRect.top,
      },
    });
  }, [closeContextMenu, openTransitionContextMenu]);

  const handleNodeClick: ReactFlow.NodeMouseHandler = useCallback((_, node) => {
    closeEditPanel();
    openStateEditPanel({ stateName: node.id });
  }, [closeEditPanel, openStateEditPanel]);

  const handleEdgeClick: ReactFlow.EdgeMouseHandler = useCallback((_, edge) => {
    closeEditPanel();
    openTransitionsEditPanel({ transitionName: edge.id });
  }, [closeEditPanel, openTransitionsEditPanel]);

  const updateFlowFunction = useCallback(() => {
    const { nodes, edges } = makeFlowFromAnimatorController(getAnyAnimationController());

    setNodes(nodes);
    setEdges(edges);
  }, [getAnyAnimationController, setNodes, setEdges]);

  useRequestAnimationFrame(updateFlowFunction);

  return (
    <>
      <ReactFlow.ReactFlow
        ref={paneRef}
        nodes={nodes}
        edges={edges}
        snapGrid={reactFlowConfig.snapGrid}
        snapToGrid
        onNodesChange={handleNodeChange}
        onConnect={handleConnect}
        nodeTypes={reactFlowConfig.nodeTypes}
        edgeTypes={reactFlowConfig.edgeTypes}
        defaultEdgeOptions={reactFlowConfig.defaultEdgeOptions}
        onNodeClick={handleNodeClick}
        onEdgeClick={handleEdgeClick}
        fitView
        fitViewOptions={{ padding: 1 }}
        onPaneClick={() => {
          closeContextMenu();
          closeEditPanel();
          openParameterEditPanel();
        }}
        onMoveStart={() => closeContextMenu()}
        onContextMenu={(event) => event.preventDefault()}
        onNodeContextMenu={handleNodeContextMenuTrigger}
        onPaneContextMenu={handlePaneContextMenuTrigger}
        onEdgeContextMenu={handleEdgeContextMenuTrigger}
        connectionLineComponent={TransitionFlowConnector}
        connectionLineStyle={reactFlowConfig.connectionLineStyle}
      >
        <Background
          id="1"
          gap={25}
          color="#f1f1f1"
          variant={BackgroundVariant.Lines}
        />
        <Background
          id="2"
          gap={100}
          offset={1}
          color="#ccc"
          variant={BackgroundVariant.Lines}
        />
      </ReactFlow.ReactFlow>
    </>
  );
};
