import { CharacterControllerScript } from '../CharacterController.script';
import { AnimatorComponent } from '@own/engine';

export abstract class CharacterLogicState {
  constructor(
    protected readonly _characterController: CharacterControllerScript
  ) {
  }

  public abstract update(dt: number): CharacterLogicState;

  public enter(dt: number): CharacterLogicState {
    return this.update(dt);
  }

  public exit(dt: number): number {
    return dt;
  }

  public transitionTo<T extends CharacterLogicState>(StateType: new (cc: CharacterControllerScript) => T, dt: number): CharacterLogicState {
    return new StateType(this._characterController).enter(this.exit(dt));
  }

  protected getAnimatorComponent(): AnimatorComponent {
    return this._characterController.entity.getComponent(AnimatorComponent);
  }

  protected getAnimatorParameters() {
    return this.getAnimatorComponent().getController().rootStateMachine.parameters;
  }
}
