import { FC, Fragment, useState } from 'react';
import {
  FormControl,
  Text,
  CardHeader,
  Card,
  CardBody,
  Button,
  Select,
  NumberInput,
  Checkbox,
  NumberInputField,
  IconButton,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import { AnimatorConditionMode, AnimatorStateTransition } from '@own/engine';
import { PanelInputNumberField } from '../PanelInputNumberField';
import { PanelCheckboxField } from '../PanelCheckboxField';
import { useEngine } from '../../hooks';
import { FaTrashCan } from 'react-icons/fa6';

export type TransitionBlockProps = {
  transition?: AnimatorStateTransition;
}

// todo: decompose this component, right now it's too big
export const TransitionBlock: FC<TransitionBlockProps> = (props) => {
  const { transition } = props;
  const controller = useEngine().getAnyAnimationController();
  // todo: very bad (but fast) ui solution, need sync all controller state in store
  const [, setRerender] = useState(0);

  const incrementRerender = () => setRerender((prev) => prev + 1);
  const firstParamName = controller.parameters.getParameterNames()[0];

  if (!transition) return null;

  return <Card
    width="100%"
    height="100%"
  >
    <CardHeader>
      <Text fontSize="xl">Transition: {transition.name}</Text>
    </CardHeader>
    <CardBody>
      <FormControl>
        <PanelInputNumberField
          name="Source motion duration"
          defaultValue={transition.sourceState.motion?.duration ?? 0}
          isDisabled
        />
        <PanelCheckboxField
          name="Use exit time"
          checked={transition.useExitTime}
          onChange={(value) => {
            if (!transition) return;

            transition.useExitTime = value;
          }}
        />
        <PanelInputNumberField
          name="Exit time"
          defaultValue={transition.exitTime}
          onChange={(value) => {
            if (!transition) return;

            transition.exitTime = value;
          }}
        />
        <PanelInputNumberField
          name="Duration"
          defaultValue={transition.duration}
          onChange={(value) => {
            if (!transition) return;

            transition.duration = value;
          }}
        />
      </FormControl>
      <Card>
        <CardHeader padding="10px">
          <Text fontSize="sm">Conditions</Text>
        </CardHeader>
        <CardBody padding="10px">
          <FormControl>
            {transition.conditions.entries.map((entry) => (
              <Fragment key={entry.uuid}>
                <SimpleGrid
                  templateColumns="1fr 1fr"
                  gap="10px"
                >
                  <Select
                    required
                    value={entry.parameterName}
                    size="xs"
                    onChange={(e) => {
                      if (!transition) return;

                      transition.conditions.updateConditionName(entry, e.target.value);
                      incrementRerender();
                    }}
                  >
                    {controller.parameters.getParameterNames().map((name) => <option
                      key={name}
                      value={name}
                    >
                      {name}
                    </option>)}
                  </Select>
                  <Select
                    required
                    value={entry.mode}
                    size="xs"
                    onChange={(e) => {
                      if (!transition) return;

                      transition.conditions.updateConditionMode(entry, e.target.value as AnimatorConditionMode);
                      incrementRerender();
                    }}
                  >
                    {transition.conditions.getConditionSupportedModes(entry).map((type) => <option
                      value={type}
                      key={type}
                    >
                      {type}
                    </option>)}
                  </Select>
                  {typeof entry.value === 'number' && <NumberInput
                    defaultValue={entry.value}
                    size="xs"
                    onChange={(_, value) => {
                      if (!transition) return;

                      transition.conditions.updateConditionValue(entry, value);
                      incrementRerender();
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>}
                  {typeof entry.value === 'boolean' && <Checkbox
                    defaultChecked={entry.value}
                    onChange={(e) => {
                      if (!transition) return;

                      transition.conditions.updateConditionValue(entry, e.target.checked);
                      incrementRerender();
                    }}
                  />}
                  <IconButton
                    flexShrink={0}
                    aria-label="delete parameter"
                    colorScheme="red"
                    icon={<FaTrashCan />}
                    size="xs"
                    onClick={() => {
                      if (!transition) return;

                      transition.conditions.deleteCondition(entry);
                      incrementRerender();
                    }}
                  />
                </SimpleGrid>
                <Divider margin="10px 0" />
              </Fragment>))}
          </FormControl>
        </CardBody>
        <Button
          size="xs"
          colorScheme="blue"
          width="auto"
          isDisabled={!firstParamName}
          onClick={() => {
            if (!transition) return;
            if (typeof firstParamName === 'undefined') return;

            transition.conditions.addCondition(firstParamName);
            incrementRerender();
          }}
        >
          Add condition
        </Button>
      </Card>
    </CardBody>
  </Card>;
};
