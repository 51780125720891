import { CharacterLogicState } from './CharacterLogicState';
import { IdleState } from './IdleState';
import { WalkState } from './WalkState';
import { CharacterActionsScript } from '../CharacterActions.script';

export class RunState extends CharacterLogicState {
  protected baseSpeed = 5.5;

  protected get actions(): CharacterActionsScript {
    return this._characterController.entity.getComponent(CharacterActionsScript);
  }

  public enter(dt: number): CharacterLogicState {
    this.getAnimatorParameters().setBooleanParameterValue('isRunning', true);

    this._characterController.getP3VCameraComponent().fov = 50;
    this._characterController.moveSpeed = this.baseSpeed;

    return this.update(dt);
  }

  public update(dt: number): CharacterLogicState {
    this._characterController.moveDirection.copy(this.actions.getMoveDirection());

    if (this._characterController.moveDirection.length() === 0) return this.transitionTo(IdleState, dt);

    if (!this.actions.actionsMap.sprintButton.isPressed()) return this.transitionTo(WalkState, dt);

    return this;
  }

  public exit(dt: number): number {
    this.getAnimatorParameters().setBooleanParameterValue('isRunning', false);

    this._characterController.getP3VCameraComponent().fov = 40;
    this._characterController.moveSpeed = 0;
    this._characterController.moveDirection.set(0, 0);

    return dt;
  }
}
