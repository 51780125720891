import * as Three from 'three';
import {
  BoxColliderComponent,
  PhysicsModule,
  RigidBodyActivationState,
  RigidBodyComponent,
  RigidBodyType,
  StaticPlaneColliderComponent,
  FbxAsset,
  VrmAsset,
  Scene,
  PerspectiveCameraComponent,
  Entity,
  CameramanBrainComponent,
} from '@own/engine';
import { ObjectsManager } from './scripts/ObjectsManager';
import { Manipulator } from './scripts/Manipulator';
import { OrbitControllerScript } from '../../packages';

const vrmGirl = '/mirafinal.vrm';
const jumpAnimation = '/JumpingDown.fbx';

export class MyScene extends Scene {
  public prepareAssets() {
    this.assetBundle.registerAsset({ path: vrmGirl }, VrmAsset);
    this.assetBundle.registerAsset({ path: jumpAnimation }, FbxAsset);
  }

  public buildScene() {
    const camera = this.entityManager.makeEntity(new Three.PerspectiveCamera());
    const light = new Three.DirectionalLight(0xffffff, 3);
    const ddm = this._ctx.getModule(PhysicsModule).getEngine().getDebuggerObject();

    light.position.set(1, 1, 1).normalize();

    camera.addComponent(PerspectiveCameraComponent);

    camera.object.position.set(0, 1.5, 5);
    camera.object.lookAt(new Three.Vector3(0, 0, 0));

    camera.addComponent(CameramanBrainComponent);

    const orbitController = this.entityManager.makeEntity(new Three.Object3D());
    orbitController.addComponent(OrbitControllerScript, { target: this.entityManager.makeEntity(new Three.Object3D()) });

    const objectsManager = this.entityManager.makeEntity(new Three.Object3D());
    objectsManager.addComponent(ObjectsManager);

    const manipulator = this.entityManager.makeEntity(new Three.Object3D());
    const manipulatorComponent = manipulator.addComponent(Manipulator);
    manipulatorComponent.camera = camera;
    manipulatorComponent.orbitController = orbitController;

    this.add(manipulator.object);
    this.add(ddm);
    this.add(camera.object);
    this.add(light);
    this.add(orbitController.object);
    this.add(this.makePlane().object);
    this.add(this.makeStaticBox().object);
    this.add(new Three.AxesHelper(5));
    this.add(objectsManager.object);
  }

  protected makePlane(): Entity {
    const geometry = new Three.PlaneGeometry(10, 10);
    const material = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#5f91c4'), side: Three.DoubleSide });
    const planeMesh = new Three.Mesh(geometry, material);
    const plane = this.entityManager.makeEntity(planeMesh);

    geometry.rotateX(Math.PI / 2);
    planeMesh.name = 'floor';

    plane.addComponent(RigidBodyComponent, {
      type: RigidBodyType.Static,
      mass: 0,
    });
    plane.addComponent(StaticPlaneColliderComponent);

    return plane;
  }

  protected makeStaticBox(): Entity {
    const geometry = new Three.BoxGeometry(4, 2, 4);
    const material = new Three.MeshStandardMaterial({ color: new Three.Color().setStyle('#ffc555'), side: Three.DoubleSide });
    const boxMesh = new Three.Mesh(geometry, material);
    const box = this.entityManager.makeEntity(boxMesh);

    boxMesh.position.set(-3, 1, -3);
    boxMesh.geometry = geometry;
    boxMesh.material = material;

    box.addComponent(RigidBodyComponent, {
      type: RigidBodyType.Static,
      mass: 0,
      activationState: RigidBodyActivationState.CanSleep,
    });
    box.addComponent(BoxColliderComponent).size.set(4, 2, 4);

    return box;
  }
}
