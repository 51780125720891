import { ChangeEvent, FC, useCallback } from 'react';
import {
  Checkbox,
  Divider,
  HStack, IconButton,
  Text,
} from '@chakra-ui/react';
import { FaTrashCan } from 'react-icons/fa6';

export type PanelCheckboxFieldProps = {
  name: string;
  checked?: boolean;
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
  removable?: boolean;
  onRemove?: () => void;
}

export const PanelCheckboxField: FC<PanelCheckboxFieldProps> = (props) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!props.onChange) return;

    props.onChange(event.target.checked);
  }, [props]);


  return <>
    <HStack>
      <Text
        fontSize="xs"
        flexGrow={1}
        flexShrink={1}
      >
        {props.name}
      </Text>
      <Checkbox
        flexShrink={0}
        isDisabled={props.isDisabled}
        defaultChecked={props.checked}
        onChange={handleChange}
      />
      {props.removable && <IconButton
        aria-label="delete parameter"
        colorScheme="red"
        icon={<FaTrashCan />}
        size="xs"
        onClick={props.onRemove}
      />}
    </HStack>
    <Divider margin="10px 0" />
  </>;
};
