import { FC, useCallback } from 'react';
import {
  FormControl,
  NumberInput,
  NumberInputField,
  Text,
  CardHeader,
  Card,
  CardBody,
  HStack,
  Divider,
  NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
} from '@chakra-ui/react';
import { useEditorStore } from '../../store';
import { useEngine } from '../../hooks';

export const StateEditPanel: FC = () => {
  const { stateEditPanel } = useEditorStore();
  const { getAnyAnimationController } = useEngine();

  const state = getAnyAnimationController().rootStateMachine.states.find((state) => state.name === stateEditPanel.stateName);

  const handleTimeScaleChange = useCallback((speed: string) => {
    if (!state) return;

    if (!state.motion) return;

    state.motion.timeScale = parseFloat(speed);
  }, [state]);

  if (!stateEditPanel.isActive) return null;
  if (!state) return null;

  return <Card
    width="100%"
    height="100%"
    key={state.name}
  >
    <CardHeader>
      <Text fontSize="xl">State: {state.name}</Text>
    </CardHeader>
    <CardBody>
      <FormControl>
        <HStack>
          <Text
            fontSize="xs"
            flexGrow={1}
            flexShrink={1}
          >
            Time scale
          </Text>
          <NumberInput
            width="100px"
            flexGrow={0}
            flexShrink={0}
            min={0}
            max={5}
            step={0.1}
            size="xs"
            isDisabled={!state.motion}
            defaultValue={state.motion?.timeScale}
            onChange={handleTimeScaleChange}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </HStack>
        <Divider margin="10px 0" />
      </FormControl>
    </CardBody>
  </Card>;
};
