import StateFlowNode from '../StateFlowNode/StateFlowNode';
import StateTransitionFlowEdge from '../TransitionFlowEdge/StateTransitionFlowEdge';

export const reactFlowConfig = {
  snapGrid: [25, 25] as [number, number],
  nodeTypes: { custom: StateFlowNode },
  edgeTypes: { floating: StateTransitionFlowEdge },
  connectionLineStyle: {
    strokeWidth: 3,
    stroke: 'black',
  },
  defaultEdgeOptions: {
    animated: true,
    style: {
      stroke: 'white',
    },
  },
};
