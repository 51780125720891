import { FC, useCallback } from 'react';
import { Button, Popover, PopoverBody, PopoverContent, VStack } from '@chakra-ui/react';
import { useEngine } from '../../hooks';
import { useEditorStore } from '../../store';
import { AnimatorStateTransition } from '@own/engine';

export const TransitionFlowNodeMenu: FC = () => {
  const { getAnyAnimationController } = useEngine();
  const { closeContextMenu, transitionContextMenu } = useEditorStore();

  const transition: AnimatorStateTransition | undefined = getAnyAnimationController().rootStateMachine
    .findStateTransitionByName(transitionContextMenu.transitionName);

  const siblingTransition: AnimatorStateTransition | undefined = transition?.destinationState
    ?.transitions?.find((t) => t.destinationState === transition?.sourceState);

  const handleDeleteClick = useCallback(() => {
    if (!transition) return;

    closeContextMenu();
    transition.sourceState.removeTransitionTo(transition.destinationState);
  }, [closeContextMenu, transition]);

  const handleDeleteSiblingClick = useCallback(() => {
    if (!siblingTransition) return;

    closeContextMenu();
    siblingTransition.sourceState.removeTransitionTo(siblingTransition.destinationState);
  }, [closeContextMenu, siblingTransition]);

  return <div style={{ position: 'absolute', left: transitionContextMenu.position.x, top: transitionContextMenu.position.y }}>
    <Popover isOpen={transitionContextMenu.isActive}>
      <PopoverContent width="auto">
        <PopoverBody>
          <VStack>
            <Button
              colorScheme="red"
              size="sm"
              onClick={handleDeleteClick}
              width="auto"
            >
              Delete {transitionContextMenu.transitionName}
            </Button>
            {!!siblingTransition && <Button
              colorScheme="red"
              size="sm"
              onClick={handleDeleteSiblingClick}
              width="auto"
            >
              Delete {siblingTransition.name}
            </Button>}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  </div>;
};
