import { FC } from 'react';
import {
  Card,
  VStack,
} from '@chakra-ui/react';
import { useEditorStore } from '../../store';
import { useEngine } from '../../hooks';
import { TransitionBlock } from './TransitionBlock';

export const TransitionsEditPanel: FC = () => {
  const { transitionsEditPanel } = useEditorStore();
  const { getAnyAnimationController } = useEngine();

  if (!transitionsEditPanel.isActive) return null;


  const targetTransition = getAnyAnimationController()
    .rootStateMachine.findStateTransitionByName(transitionsEditPanel.transitionName);

  if (!targetTransition) return null;

  const siblingTransitions = targetTransition.destinationState.transitions
    .find((transition) => transition.destinationState === targetTransition.sourceState);

  return <Card
    width="100%"
    height="100%"
    key={targetTransition.name + targetTransition.destinationState.name}
  >
    <VStack height="100%">
      <TransitionBlock transition={targetTransition} />
      <TransitionBlock transition={siblingTransitions} />
    </VStack>
  </Card>;
};
