import { useEffect } from 'react';

export const useRequestAnimationFrame = (callback: () => void) => {
  useEffect(() => {
    let animationFrameId: number;

    const tick = () => {
      animationFrameId = requestAnimationFrame(tick);
      callback();
    }

    tick();

    return () => {
      cancelAnimationFrame(animationFrameId);
    }
  }, [callback])
}
