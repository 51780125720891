import { FC, useCallback } from 'react';
import { EdgeProps, getStraightPath, useStore } from 'reactflow';
import { Theme, useTheme } from '@chakra-ui/react';
import { getEdgeParams } from '../../utils';
import { useEditorStore } from '../../store';

const StateTransitionFlowEdge: FC<EdgeProps> = (props) => {
  const { id, source, target, markerEnd } = props;
  const { transitionsEditPanel } = useEditorStore();
  const theme = useTheme<Theme>();
  const { transitionName: activeTransitionName } = transitionsEditPanel;

  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  const siblingEdge = useStore(useCallback((store) => {
    return store.edges.find((edge) => edge.source === target && edge.target === source);
  }, [source, target]));

  const siblingIsActive = siblingEdge && siblingEdge.data.isActive;

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  const isEditable = activeTransitionName &&
    (activeTransitionName === id || activeTransitionName === siblingEdge?.id);

  return (
    <>
      {!siblingIsActive && <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={!siblingIsActive ? markerEnd : undefined}
        markerStart={(!siblingIsActive && siblingEdge) ? markerEnd : undefined}
        pathLength="100"
        style={{
          animation: 'none',
          strokeWidth: 10,
          stroke: isEditable ? theme.colors.pink['500'] : theme.colors.blue['800'],
          strokeDashoffset: 0,
          strokeDasharray: 100,
        }}
      />}
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        pathLength="100"
        style={{
          animation: 'none',
          strokeWidth: 4,
          stroke: 'aqua',
          strokeDashoffset: 100 - props.data.transitionProgress * 100,
          strokeDasharray: 100,
          strokeLinecap: 'round',
        }}
      />
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        pathLength="100"
        style={{
          animation: 'none',
          strokeWidth: 30,
          stroke: 'transparent',
          strokeLinecap: 'round',
        }}
      />
    </>
  );
};

export default StateTransitionFlowEdge;
